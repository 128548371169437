import { cpnjMask } from "./cnpjMask";
import { cpfMask } from "./cpfMask";

export function cpfCpnjMask(value: string): string {
  value = value.replace(/\D/g, ""); // remove não númericos
  value = value.substring(0, 14); // limita caracteres

  if (value.length <= 11) {
    return cpfMask(value);
  }

  return cpnjMask(value);
}
