import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  fromPromise,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  RefreshAccessTokenResponse,
  RefreshAccessTokenVariables,
  refreshAccessTokenMutation,
} from "~/graphql/mutations/refresh-access-token";
import { navigationRef } from "~/routes/navigation-ref";
import { getAccessToken, setAccessToken } from "~/utils/accessToken";
import { API_BASE_URL, storageRefreshTokenKey } from "~/utils/constants";

const authLink = setContext((_, context) => {
  const token = getAccessToken();

  return {
    ...context,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      ...context.headers,
    },
  };
});

const httpLink = createHttpLink({
  uri: `${API_BASE_URL}/graphql`,
  fetch(uri, options) {
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error("Esta requisição demorou muito para responder."));
      }, 30000); // 30 seconds

      fetch(uri, options).then(
        response => {
          clearTimeout(timeout);
          resolve(response);
        },
        error => {
          clearTimeout(timeout);
          reject(error);
        },
      );
    });
  },
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors?.some(error => error.message === "Unauthorized")) {
    try {
      return fromPromise(
        AsyncStorage.getItem(storageRefreshTokenKey).then(
          async refreshToken => {
            if (!refreshToken) {
              throw new Error("No refresh token in storage.");
            }

            const { data } = await apolloClient.mutate<
              RefreshAccessTokenResponse,
              RefreshAccessTokenVariables
            >({
              fetchPolicy: "network-only",
              mutation: refreshAccessTokenMutation,
              variables: {
                refreshToken,
              },
            });

            if (!data) {
              throw new Error("No refresh token data.");
            }

            AsyncStorage.setItem(
              storageRefreshTokenKey,
              data.refreshAccessToken.refreshToken,
            ).catch(console.error);

            setAccessToken(data.refreshAccessToken.accessToken);
            const context = operation.getContext();

            operation.setContext({
              ...context,
              headers: {
                ...context.headers,
                authorization: `Bearer ${data.refreshAccessToken.accessToken}`,
              },
            });
          },
        ),
      ).flatMap(() => forward(operation));
    } catch (error) {
      console.log("Error refresh token", error);

      if (navigationRef.isReady()) {
        navigationRef.navigate("Login");
        setAccessToken(null);
        AsyncStorage.removeItem(storageRefreshTokenKey).catch(console.error);
      }
    }
  }
});

export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache({
    typePolicies: {},
  }),
});
