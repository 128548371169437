import { useFocusEffect, useNavigation } from "@react-navigation/native";
import classNames from "classnames";
import { useCallback } from "react";
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  Platform,
  Text,
  View,
} from "react-native";
import CardEleva from "~/assets/icons/card-eleva.svg";
import { Button } from "~/components/Button";
import { MyCreditCard } from "~/components/MyCreditCard";
import { QueryFailed } from "~/components/QueryFailed";
import { SvgIcon } from "~/components/SvgIcon";
import { useMyCardsQuery } from "~/hooks/useMyCards";

export const Cards: React.FC = () => {
  const { navigate } = useNavigation();
  const { data, loading, refetch, error } = useMyCardsQuery();

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  return (
    <ImageBackground
      className="flex-1 bg-[#F4FBFF]"
      source={require("assets/gradient-eleva-2.png")}
      resizeMode="cover"
    >
      <View className="mt-16 flex-1">
        <Text className="self-center font-open-sans-400 text-sm font-normal uppercase  text-black opacity-60">
          seus cartões
        </Text>
        <View className="flex-row  self-center">
          <Text className="font-inter-400 text-sm font-normal text-black opacity-60">
            Foram pagas{"  "}
          </Text>
          <Text className="font-inter-700 text-sm font-bold text-black opacity-80">
            0
          </Text>
          <Text className="font-inter-400 text-sm font-normal text-black opacity-60">
            {"  "}
            contas neste mês
          </Text>
        </View>
        <View
          style={{ elevation: 8 }}
          className={classNames(
            "mt-6 self-center",
            Platform.OS === "web" && "shadow-lg",
          )}
        >
          <SvgIcon component={CardEleva} />
        </View>
        <View className="mt-5 px-6">
          <Button text="ADICIONAR" onPress={() => navigate("RegisterCard")} />
        </View>
        <View className="mt-4 flex-1">
          {loading ? (
            <View className="mt-10">
              <ActivityIndicator size={40} />
            </View>
          ) : !data || error ? (
            <QueryFailed refetch={refetch} />
          ) : (
            <FlatList
              data={data?.myCards}
              keyExtractor={card => card.id}
              ListEmptyComponent={() => (
                <View>
                  <Text className="mt-10 p-4 text-center font-inter-400 text-sm font-semibold text-black opacity-80">
                    Parece que você ainda não cadastrou nenhum cartão 😕
                  </Text>
                </View>
              )}
              contentContainerStyle={{
                paddingHorizontal: 24,
                paddingBottom: 12,
              }}
              ItemSeparatorComponent={() => <View className="my-3" />}
              renderItem={({ item }) => (
                <MyCreditCard
                  brand={item.brand || "N/A"}
                  cardNumber={item.number}
                  date={item.expirationDate}
                  holderName={item.holderName}
                />
              )}
            />
          )}
        </View>
      </View>
    </ImageBackground>
  );
};
