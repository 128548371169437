import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  SubsidiesQueryResponse,
  SubsidiesQueryVariables,
  subsidiesQuery,
} from "~/graphql/queries/subsidies";

export function useSubsidiesQuery(
  options?: QueryHookOptions<SubsidiesQueryResponse, SubsidiesQueryVariables>,
) {
  return useQuery<SubsidiesQueryResponse, SubsidiesQueryVariables>(
    subsidiesQuery,
    options,
  );
}
