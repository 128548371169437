import { gql } from "@apollo/client";

export interface MyPointsHistoryResponse {
  myPointsHistory: {
    balance: number;
    earned: number;
    used: number;
    history: Array<{
      date: string;
      pointsAtStartOfDay: number;
      pointsAtEndOfDay: number;
      logs: Array<{
        id: string;
        points: number;
        description: string;
      }>;
    }>;
  };
}

export const myPointsHistoryQuery = gql`
  query MyPointsHistory {
    myPointsHistory {
      balance
      earned
      used
      history {
        date
        pointsAtStartOfDay
        pointsAtEndOfDay
        logs {
          id
          points
          description
          createdAt
        }
      }
    }
  }
`;
