import { useFocusEffect } from "@react-navigation/native";
import classNames from "classnames";
import dayjs from "dayjs";
import { useCallback } from "react";
import { ActivityIndicator, Platform, Text, View } from "react-native";
import { QueryFailed } from "~/components/QueryFailed";
import { useMyBillingsQuery } from "~/hooks/useMyBillings";
import { dateLabelMap } from "~/utils/constants";
import { formatCurrency } from "~/utils/formatCurrency";

function formatDateTime(date: string) {
  return new Date(date).toLocaleString("pt-BR", {
    day: "2-digit",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

interface PaymentCardsProps {
  isCharged: boolean;
  filterDate?: dayjs.Dayjs;
}

export const PaymentCards: React.FC<PaymentCardsProps> = ({
  filterDate,
  isCharged,
}) => {
  const { data, error, loading, refetch } = useMyBillingsQuery({
    variables: {
      filters: {
        isCharged,
        dueDate: {
          lte: dayjs(filterDate).endOf("month").format("YYYY-MM-DD"),
          gte: dayjs(filterDate).startOf("month").format("YYYY-MM-DD"),
        },
      },
    },
  });

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  const firstBilling = data?.myBillings.billings.at(0);
  const date = dayjs(firstBilling?.dueDate);
  const year = date.format("YYYY");
  const month = date.format("MM");
  const day = date.format("DD");

  return (
    <View className="relative items-center pb-10">
      {loading ? (
        <View className="z-40 mt-14 p-6">
          <ActivityIndicator size={40} />
        </View>
      ) : error ? (
        <View className="z-40 mt-8 p-6">
          <QueryFailed refetch={refetch} />
        </View>
      ) : !firstBilling ? (
        <View className="z-40 mt-16 p-6">
          <Text className="font-open-sans-500 text-center text-[#999999]">
            Ainda não há nenhuma conta paga{"\n"}neste mês!
          </Text>
        </View>
      ) : (
        <View
          style={{ elevation: 4 }}
          className={classNames(
            "z-40 h-[184px] w-[333px] rounded-lg bg-white p-4",
            Platform.OS === "web" && "shadow-md",
          )}
        >
          <View className="w-full flex-row justify-between">
            <View className="items-start justify-between">
              <Text className="font-open-sans-600 text-base font-semibold text-[#0789E5]">
                {firstBilling.name}
              </Text>

              {typeof firstBilling.recurrenceMonths === "number" &&
                typeof firstBilling.recurrenceNumber === "number" && (
                  <Text className="mb-1 mt-2 font-open-sans-400 text-xs text-[#CCCCCC]">
                    Fatura {firstBilling.recurrenceNumber} de{" "}
                    {firstBilling.recurrenceMonths}
                  </Text>
                )}

              <Text className="justify-center text-center font-open-sans-400 text-sm font-normal text-[#999999]">
                {formatCurrency(Number(firstBilling.price))}
              </Text>
            </View>

            <View className="items-center">
              <Text className="font-roboto-mono-300 text-xs text-[#CCCCCC]">
                {year}
              </Text>

              <Text className="font-roboto-mono-300 text-lg uppercase text-[#CCCCCC]">
                {dateLabelMap[month]}
              </Text>

              <Text className="font-roboto-mono-400 text-3xl text-[#CCCCCC]">
                {day}
              </Text>
            </View>
          </View>

          <Text className="my-4 font-open-sans-400 font-normal text-[#999999]">
            {firstBilling.description}
          </Text>

          {firstBilling.paidAt ? (
            <Text className="mt-auto text-center font-open-sans-400 text-xs font-normal text-[#999999]">
              Foi pago dia {formatDateTime(firstBilling.paidAt)}
            </Text>
          ) : firstBilling.chargedAt ? (
            <Text className="mt-auto text-center font-open-sans-400 text-xs font-normal text-[#999999]">
              Foi cobrado dia {formatDateTime(firstBilling.chargedAt)}
            </Text>
          ) : null}
        </View>
      )}

      <View
        style={{ elevation: 4 }}
        className={classNames(
          "absolute top-7 h-[184px] w-[293px] items-center justify-center rounded-lg bg-white p-4",
          Platform.OS === "web" && "shadow-md",
        )}
      />

      <View
        style={{ elevation: 4 }}
        className={classNames(
          "absolute top-5 h-[184px] w-[303px] items-center justify-center rounded-lg bg-white p-4",
          Platform.OS === "web" && "shadow-md",
        )}
      />

      <View
        style={{ elevation: 4 }}
        className={classNames(
          "absolute top-2.5 h-[184px] w-[313px] items-center justify-center rounded-lg bg-white p-4",
          Platform.OS === "web" && "shadow-md",
        )}
      />
    </View>
  );
};
