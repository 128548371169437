import { useFocusEffect } from "@react-navigation/native";
import classNames from "classnames";
import { useCallback } from "react";
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  Platform,
  Text,
  View,
} from "react-native";
import { ConsumePointsButton } from "~/components/ConsumePointsButton";
import { MyPointsCard } from "~/components/MyPointsCard";
import { QueryFailed } from "~/components/QueryFailed";
import { useMyPointsHistory } from "~/hooks/useMyPointsHistory";

export const MyPoints: React.FC = () => {
  const { data, error, loading, refetch } = useMyPointsHistory();

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  return (
    <ImageBackground
      resizeMode="cover"
      source={require("assets/gradient-eleva-4.png")}
      className={classNames(
        "h-[440px] flex-1 bg-white",
        Platform.OS !== "web" && "pt-6",
      )}
    >
      {loading ? (
        <View className="mt-12 items-center justify-center p-12">
          <ActivityIndicator size={40} />
        </View>
      ) : !data || error ? (
        <View className="mt-12 items-center justify-center p-12">
          <QueryFailed refetch={refetch} />
        </View>
      ) : (
        <>
          <ConsumePointsButton refetch={refetch} />

          <View>
            <View className="items-center">
              <Text className="font-open-sans-400 text-sm font-normal uppercase text-white">
                Sua pontuação
              </Text>

              <Text className="mt-4 font-open-sans-700 text-sm font-normal text-white">
                Foram pagas 0 contas neste mês
              </Text>

              <Text className="mt-6 text-center font-roboto-mono-500 text-6xl font-normal uppercase text-black">
                {data.myPointsHistory.balance.toLocaleString("pt-BR")}
              </Text>

              <Text className="font-open-sans-400 text-sm font-normal text-black opacity-60">
                Saldo de pontos
              </Text>
            </View>

            <View className="mt-8 flex-row items-center justify-center space-x-8">
              <View className="items-center">
                <Text className="mt-4 font-roboto-mono-500 text-2xl font-medium text-[#3EAE22] opacity-60">
                  {data.myPointsHistory.earned.toLocaleString("pt-BR")}
                </Text>

                <Text className="mt-2 font-open-sans-400 text-sm font-normal uppercase text-black opacity-60">
                  Adquiridos
                </Text>
              </View>

              <View className="items-center">
                <Text className="mt-4 font-roboto-mono-500 text-2xl font-medium text-[#EE4D1A] opacity-60">
                  {data.myPointsHistory.used.toLocaleString("pt-BR")}
                </Text>

                <Text className="mt-2 font-open-sans-400 text-sm font-normal uppercase text-black opacity-60">
                  Utilizados
                </Text>
              </View>
            </View>
          </View>

          <View className="mt-10 flex-1">
            <FlatList
              data={data.myPointsHistory.history}
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                paddingHorizontal: 24,
                paddingVertical: 24,
              }}
              renderItem={({ item }) => (
                <View className="ml-4">
                  <MyPointsCard history={item} />
                </View>
              )}
            />
          </View>
        </>
      )}
    </ImageBackground>
  );
};
