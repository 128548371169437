import { gql } from "@apollo/client";

export interface CreateCardMutationResponse {
  id: string;
}

export interface CreateCardMutationVariables {
  input: {
    number: string;
    holderName: string;
    expirationDate: string;
    securityCode: string;
  };
}

export const createCardMutation = gql`
  mutation CreateCard($input: CreateCardInput!) {
    createCard(input: $input) {
      id
    }
  }
`;
