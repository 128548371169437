import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { ImageBackground, TouchableOpacity, View } from "react-native";

interface LayoutProps {
  hasBackButton?: boolean;
  children?: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children, hasBackButton }) => {
  const { goBack } = useNavigation();

  return (
    <ImageBackground
      className="flex-1 bg-white"
      source={require("../assets/eleva-bg-2.png")}
      resizeMode="cover"
    >
      {hasBackButton ? (
        <View className="mt-8 flex-row items-center p-6">
          <TouchableOpacity onPress={goBack}>
            <AntDesign name="arrowleft" size={24} color="white" />
          </TouchableOpacity>
        </View>
      ) : null}
      <View className="flex-1">{children}</View>
    </ImageBackground>
  );
};
