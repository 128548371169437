import { createStackNavigator } from "@react-navigation/stack";
import { CreateSubsidy } from "~/pages/CreateSubsidy";
import { RegisterSubsidySuccess } from "~/pages/RegisterSubsidySuccess";
import { Subsidies } from "~/pages/Subsidies";

const { Navigator, Screen } = createStackNavigator();

export const SubsidiesStackRoutes = () => (
  <Navigator screenOptions={{ headerShown: false }}>
    <Screen
      name="Subsidies"
      component={Subsidies}
      options={{ title: "Subsídios" }}
    />

    <Screen
      name="CreateSubsidy"
      component={CreateSubsidy}
      options={{ title: "Cadastrar subsídio" }}
    />

    <Screen
      name="RegisterSubsidySuccess"
      component={RegisterSubsidySuccess}
      options={{ title: "Cadastro de subsídio com sucesso" }}
    />
  </Navigator>
);
