export function cpfMask(value: string): string {
  value = value.replace(/\D/g, ""); // remove não númericos
  value = value.substring(0, 11); // limita caracteres

  if (value.length < 4) {
    return value;
  }

  if (value.length < 7) {
    return `${value.substring(0, 3)}.${value.substring(3)}`;
  }

  if (value.length < 10) {
    return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(
      6,
    )}`;
  }

  return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(
    6,
    9,
  )}-${value.substring(9)}`;
}
