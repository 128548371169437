import { gql } from "@apollo/client";
import type { UserRole } from "~/types/UserRole";

export interface LoginMutationResponse {
  login: {
    accessToken: string;
    refreshToken: string;
    user: {
      id: string;
      cpf: string;
      name: string;
      role: UserRole | null;
      email: string;
      birthDate: string;
      createdAt: string;
      phoneNumber: string;
      homeAddress?: {
        city: string;
        state: string;
        street: string;
        number: string | null;
        zipcode: string;
        district: string;
        complement: string | null;
      } | null;
    };
  };
}

export interface LoginMutationVariables {
  input: {
    email: string;
    password: string;
  };
}

export const loginMutation = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      accessToken
      refreshToken
      user {
        id
        cpf
        name
        role
        email
        birthDate
        createdAt
        phoneNumber
        homeAddress {
          city
          state
          street
          number
          zipcode
          district
          complement
        }
      }
    }
  }
`;
