import { gql } from "@apollo/client";
import type { AirCompany } from "~/types/AirCompany";

export interface ConsumePointsResponse {
  consumePoints: boolean;
}

export interface ConsumePointsVariables {
  points: number;
  airCompany: AirCompany;
}

export const consumePointsMutation = gql`
  mutation ConsumePoints($points: Float!, $airCompany: AirCompany!) {
    consumePoints(input: { points: $points, airCompany: $airCompany })
  }
`;
