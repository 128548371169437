import dayjs from "dayjs";
import { z } from "zod";
import { BillingTransferAccountType } from "~/types/billing-transfer-account";
import { BillingTransactionType } from "~/types/billling-transaction-type";
import { isValidCnpj } from "~/utils/isValidCnpj";
import { isValidCpf } from "~/utils/isValidCpf";
import { DateConverter } from "~/utils/masks/DateConverter";
import { PixKeyType } from "../types/pix-key-type";

export const createBillingValidationSchema = z
  .object({
    cardId: z.string({ required_error: "Selecione um cartão." }).trim(),
    name: z.string({ required_error: "Este campo é obrigatório." }).trim(),
    description: z
      .string({ required_error: "Este campo é obrigatório." })
      .trim(),
    price: z
      .string({ required_error: "Este campo é obrigatório." })
      .transform(price => price.replace(/[^0-9,]/g, "").replace(",", "."))
      .refine(price => !isNaN(Number(price)), "Digite um preço válido."),
    paymentDate: z
      .string({ required_error: "Este campo é obrigatório." })
      .trim()
      .min(10, "Digite uma data válida.")
      .transform(DateConverter.fromBrazilianToUniversal)
      .refine(paymentDate => {
        return dayjs(paymentDate, "YYYY-MM-DD", true).isValid();
      }, "Data inválida")
      .refine(paymentDate => {
        return !dayjs(paymentDate, "YYYY-MM-DD", true)
          .subtract(30, "days")
          .isBefore(new Date(), "day");
      }, "Só é possível cadastrar contas que vencem a partir de 30 dias."),
    recurrenceMonths: z.coerce.number().int().optional(),
    favoredName: z
      .string({ required_error: "Este campo é obrigatório." })
      .trim()
      .transform(name => name.replace(/\s+/g, " "))
      .refine(name => {
        const regex = /[^\s]{3,}\s(.)+[^\s]{3,}/gi;
        return regex.test(name);
      }, "Digite um nome válido."),
    cpfCnpj: z
      .string({ required_error: "Este campo é obrigatório." })
      .trim()
      .refine(
        cpfCnpj => isValidCpf(cpfCnpj) || isValidCnpj(cpfCnpj),
        "Este CPF/CNPJ é inválido.",
      ),
    transactionType: z.nativeEnum(BillingTransactionType, {
      required_error: "Este campo é obrigatório.",
    }),
    pixKeyType: z.nativeEnum(PixKeyType).optional(),
    pixKey: z.string().trim().optional(),
    compe: z.string().trim().min(3).optional(),
    branch: z.string().trim().optional(),
    account: z.string().trim().optional(),
    accountType: z.nativeEnum(BillingTransferAccountType).optional(),
  })
  .superRefine((values, context) => {
    if (values.transactionType === BillingTransactionType.pix) {
      if (!values.pixKeyType) {
        context.addIssue({
          code: "custom",
          path: ["pixKeyType"],
          message: "Este campo é obrigatório.",
        });
      }
      if (!values.pixKey) {
        context.addIssue({
          code: "custom",
          path: ["pixKey"],
          message: "Este campo é obrigatório.",
        });
      }
    } else if (values.transactionType === BillingTransactionType.ted) {
      if (!values.compe) {
        context.addIssue({
          code: "custom",
          path: ["compe"],
          message: "Este campo é obrigatório.",
        });
      }
      if (!values.branch) {
        context.addIssue({
          code: "custom",
          path: ["branch"],
          message: "Este campo é obrigatório.",
        });
      }
      if (!values.account) {
        context.addIssue({
          code: "custom",
          path: ["account"],
          message: "Este campo é obrigatório.",
        });
      }
      if (!values.accountType) {
        context.addIssue({
          code: "custom",
          path: ["accountType"],
          message: "Este campo é obrigatório.",
        });
      }
    }
  });

export type CreateBillingFormInput = z.input<
  typeof createBillingValidationSchema
>;
