export function isValidCnpj(value: string): boolean {
  if (
    typeof value !== "string" ||
    !/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/.test(value)
  ) {
    return false;
  }

  value = value.replace(/[^\d]+/g, "");

  if (
    value.length !== 14 ||
    value === "00000000000000" ||
    value === "11111111111111" ||
    value === "22222222222222" ||
    value === "33333333333333" ||
    value === "44444444444444" ||
    value === "55555555555555" ||
    value === "66666666666666" ||
    value === "77777777777777" ||
    value === "88888888888888" ||
    value === "99999999999999"
  ) {
    return false;
  }

  // Valida DVs
  let size = value.length - 2;
  let numbers = value.substring(0, size);
  const digits = value.substring(size);
  let sum = 0;
  let position = size - 7;

  for (let index = size; index >= 1; index--) {
    sum += Number(numbers.charAt(size - index)) * position--;

    if (position < 2) {
      position = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result.toString() !== digits.charAt(0)) {
    return false;
  }

  size = size + 1;
  numbers = value.substring(0, size);
  sum = 0;
  position = size - 7;

  for (let index = size; index >= 1; index--) {
    sum += Number(numbers.charAt(size - index)) * position--;

    if (position < 2) {
      position = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

  if (result.toString() !== digits.charAt(1)) {
    return false;
  }

  return true;
}
