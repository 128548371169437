import classNames from "classnames";
import { Platform, Text, View } from "react-native";

interface NotificationCardProps {
  title: string;
  message: string;
}

export const NotificationCard: React.FC<NotificationCardProps> = ({
  title,
  message,
}) => {
  return (
    <View
      style={{ elevation: 3 }}
      className={classNames(
        "w-full items-start justify-center rounded-lg bg-white p-4",
        Platform.OS === "web" && "shadow",
      )}
    >
      <Text className="font-open-sans-600 font-semibold text-black">
        {title}
      </Text>

      <Text className="mt-2 text-justify font-open-sans-400 text-[#999999]">
        {message}
      </Text>
    </View>
  );
};
