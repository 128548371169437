import { Entypo } from "@expo/vector-icons";
import { useRef, useState } from "react";
import { Modal, Pressable, Text, TouchableOpacity } from "react-native";
import { SelectDropdown } from "./SelectDropdown";

export interface Dimensions {
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface SelectOption {
  value: string;
  label: string;
}

interface SelectProps {
  isDisabled?: boolean;
  placeholder?: string;
  options: SelectOption[];
  closeOnSelect?: boolean;
  onSelect: (value: SelectOption["value"]) => void;
  selectedOptionValue: SelectOption["value"] | null;
  isOptionSelected?: (option: SelectOption) => boolean;
}

export const Select: React.FC<SelectProps> = ({
  options,
  onSelect,
  isDisabled,
  placeholder,
  closeOnSelect,
  isOptionSelected,
  selectedOptionValue,
}) => {
  const buttonRef = useRef<TouchableOpacity>(null);
  const [dimensions, setDimensions] = useState<Dimensions | null>(null);

  const selectedOption = options.find(
    option => option.value === selectedOptionValue,
  );

  function handleOpenModal() {
    buttonRef.current?.measure((_x, _y, width, height, pageX, pageY) => {
      setDimensions({
        width,
        height,
        x: pageX,
        y: pageY,
      });
    });
  }

  function handleCloseModal() {
    setDimensions(null);
  }

  return (
    <>
      <TouchableOpacity
        ref={buttonRef}
        disabled={isDisabled}
        onPress={handleOpenModal}
        className="flex-row items-center justify-between rounded-sm border border-[#BDBDBD] bg-white p-2"
      >
        {selectedOption ? (
          <Text className="text-[#605d5d]" numberOfLines={1}>
            {selectedOption.label}
          </Text>
        ) : (
          <Text className="text-[#999999]" numberOfLines={1}>
            {placeholder}
          </Text>
        )}

        <Entypo name="chevron-small-down" size={26} color="#8C8A93" />
      </TouchableOpacity>

      <Modal
        visible={Boolean(dimensions)}
        onRequestClose={handleCloseModal}
        statusBarTranslucent
        animationType="fade"
        transparent
      >
        {dimensions && (
          <Pressable
            className="flex-1 bg-black/30"
            onPress={event => {
              if (event.target === event.currentTarget) {
                handleCloseModal();
              }
            }}
          >
            <SelectDropdown
              options={options}
              onSelect={onSelect}
              dimensions={dimensions}
              onClose={handleCloseModal}
              closeOnSelect={closeOnSelect}
              isOptionSelected={
                isOptionSelected ??
                (option => {
                  return option.value === selectedOptionValue;
                })
              }
            />
          </Pressable>
        )}
      </Modal>
    </>
  );
};
