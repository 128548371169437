import { gql } from "@apollo/client";

export interface SubsidiesQueryResponse {
  subsidies: Array<{
    id: string;
    company: string;
    cpf: string;
    amount: number;
    createdAt: string;
  }>;
}

export interface SubsidiesQueryVariables {
  filters?: {
    month: number;
    year: number;
  };
}

export const subsidiesQuery = gql`
  query Subsidies($filters: SubsidiesFiltersInput) {
    subsidies(filters: $filters) {
      id
      company
      cpf
      amount
      createdAt
    }
  }
`;
