import { createStackNavigator } from "@react-navigation/stack";
import { CreateBilling } from "~/pages/CreateBillling";
import { MyBillings } from "~/pages/MyBillings";
import { RegisterAccount } from "~/pages/RegisterAccount";
import { RegisterBillingSuccess } from "~/pages/RegisterBillingSuccess";

const { Navigator, Screen } = createStackNavigator();

export const MyBillingsStackRoutes = () => (
  <Navigator screenOptions={{ headerShown: false }}>
    <Screen
      name="MyBillings"
      component={MyBillings}
      options={{ title: "Contas" }}
    />

    <Screen
      name="RegisterAccount"
      component={RegisterAccount}
      options={{ title: "Cadastrar conta" }}
    />

    <Screen
      name="CreateBilling"
      component={CreateBilling}
      options={{ title: "Cadastrar conta" }}
    />

    <Screen
      name="RegisterBillingSuccess"
      component={RegisterBillingSuccess}
      options={{ title: "Cadastro de conta com sucesso" }}
    />
  </Navigator>
);
