import { Entypo } from "@expo/vector-icons";
import classNames from "classnames";
import { Platform, Text, View } from "react-native";
import MyCard from "~/assets/icons/my-card-eleva.svg";
import { dateLabelMap } from "~/utils/constants";
import { SvgIcon } from "./SvgIcon";

interface MyCreditCardProps {
  date: string;
  brand: string | null;
  holderName: string;
  cardNumber: string;
  isSelected?: boolean;
}

export const MyCreditCard: React.FC<MyCreditCardProps> = ({
  date,
  cardNumber,
  holderName,
  isSelected,
  brand,
}) => {
  const [month, year] = date.split("/");

  return (
    <View
      style={{ elevation: 3 }}
      className={classNames(
        "relative w-full flex-row items-center justify-between space-x-6 rounded-lg bg-white p-4",
        Platform.OS === "web" && "shadow",
      )}
    >
      <View className="">
        <View className="items-center">
          <Text className="font-roboto-mono-500 font-medium text-[#CCCCCC]">
            {year}
          </Text>
          <Text className="font-roboto-mono-500 font-semibold text-[#CCCCCC]">
            {dateLabelMap[month]}
          </Text>
        </View>
      </View>
      <View className="flex-1 items-center">
        <Text className="mt-2 text-justify font-open-sans-600 text-[#0789E5]">
          {cardNumber}
        </Text>
        <Text
          className="mt-2 text-justify font-open-sans-400 text-[#999999]"
          numberOfLines={1}
        >
          {holderName}
        </Text>
      </View>
      <View className="flex-col items-center">
        <SvgIcon component={MyCard} />
        <Text className="mt-2 text-justify font-open-sans-400 text-[#999999]">
          {brand}
        </Text>
      </View>
      {isSelected && (
        <View className="absolute right-2 top-2 aspect-square w-5 items-center justify-center rounded-full bg-green-500">
          <Entypo name="check" size={14} color="white" />
        </View>
      )}
    </View>
  );
};
