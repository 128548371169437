export function cpnjMask(value: string): string {
  value = value.replace(/\D/g, ""); // remove não númericos
  value = value.substring(0, 14); // limita caracteres

  if (value.length < 3) {
    return value;
  }

  if (value.length < 6) {
    return `${value.substring(0, 2)}.${value.substring(2)}`;
  }

  if (value.length < 9) {
    return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(
      5,
    )}`;
  }

  if (value.length < 13) {
    return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(
      5,
      8,
    )}/${value.substring(8)}`;
  }

  return `${value.substring(0, 2)}.${value.substring(2, 5)}.${value.substring(
    5,
    8,
  )}/${value.substring(8, 12)}-${value.substring(12)}`;
}
