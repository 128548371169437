export function creditCardNumberMask(value: string): string {
  value = value.replace(/\D/g, "").substring(0, 16);
  if (value.length < 5) {
    return value;
  }
  if (value.length < 9) {
    return `${value.slice(0, 4)} ${value.slice(4)}`;
  }
  if (value.length < 13) {
    return `${value.slice(0, 4)} ${value.slice(4, 8)} ${value.slice(8)}`;
  }
  return `${value.slice(0, 4)} ${value.slice(4, 8)} ${value.slice(
    8,
    12,
  )} ${value.slice(12)}`;
}
