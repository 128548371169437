import {
  ActivityIndicator,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
} from "react-native";
import CheckEleva from "~/assets/icons/check-eleva.svg";
import { SvgIcon } from "./SvgIcon";

interface ButtonProps extends TouchableOpacityProps {
  text: string;
  isLoading?: boolean;
}

export const Button: React.FC<ButtonProps> = ({
  text,
  disabled,
  className,
  isLoading,
  ...props
}) => {
  return (
    <TouchableOpacity
      {...props}
      disabled={isLoading || disabled}
      className="w-full flex-row items-center justify-center space-x-2 rounded-full bg-[#0085FF] p-4"
    >
      <SvgIcon component={CheckEleva} />

      <Text className="text-center font-inter-500 text-base text-white">
        {text}
      </Text>

      {isLoading && <ActivityIndicator size={24} color={"white"} />}
    </TouchableOpacity>
  );
};
