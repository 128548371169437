import classNames from "classnames";
import { Text, TextInput, TextInputProps, View } from "react-native";

export interface InputTextProps extends TextInputProps {
  prefix?: string;
  variant?: "primary" | "secondary" | "third";
}

export const InputText: React.FC<InputTextProps> = ({
  prefix,
  className,
  variant = "primary",
  ...props
}) => {
  return (
    <View className="relative">
      {prefix && (
        <View className="absolute z-10 h-full items-center justify-center rounded-sm border border-[#BDBDBD] bg-white px-4 text-[#999999]">
          <Text className="font-roboto-mono-500 text-sm text-[#999999]">
            {prefix}
          </Text>
        </View>
      )}
      <TextInput
        {...props}
        className={classNames(
          "h-[40px] p-3",
          prefix && "pl-16",
          {
            "rounded-sm bg-white p-3 text-black opacity-60":
              variant === "primary",
            "rounded-sm border border-[#BDBDBD] bg-white text-[#605d5d]":
              variant === "secondary",
            "rounded-sm border-2 border-[#BDBDBD] bg-white font-normal text-[#605d5d]":
              variant === "third",
          },
          className,
        )}
        placeholderTextColor="#BDBDBD"
      />
    </View>
  );
};
