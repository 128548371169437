import { gql } from "@apollo/client";
import type { UserRole } from "~/types/UserRole";

export interface MeQueryResponse {
  me: {
    id: string;
    cpf: string;
    name: string;
    role: UserRole | null;
    email: string;
    birthDate: string;
    createdAt: string;
    phoneNumber: string;
    homeAddress?: {
      city: string;
      state: string;
      street: string;
      number: string | null;
      zipcode: string;
      district: string;
      complement: string | null;
    } | null;
  };
}

export const meQuery = gql`
  query Me {
    me {
      id
      cpf
      name
      role
      email
      birthDate
      createdAt
      phoneNumber
      homeAddress {
        city
        state
        street
        number
        zipcode
        district
        complement
      }
    }
  }
`;
