import { Ionicons } from "@expo/vector-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ActivityIndicator,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { InputCode } from "~/components/InputCode";
import { Layout } from "~/components/Layout";
import { InputText } from "~/components/TextInput";
import { WarningCard } from "~/components/WarningCard";
import {
  ResendVerificationCodeResponse,
  ResendVerificationCodeVariables,
  resendVerificationCodeMutation,
} from "~/graphql/mutations/resend-verification-code";
import {
  VerifyPhoneNumberResponse,
  VerifyPhoneNumberVariables,
  verifyPhoneNumberMutation,
} from "~/graphql/mutations/verify-phone-number";
import { apolloClient } from "~/lib/apollo";
import { alert } from "~/utils/alert";
import {
  VerifyCodeFormInput,
  verifyCodeValidationSchema,
} from "~/validation/verify-code";

export interface RegisterValidationParams {
  email?: string;
}

export const RegisterValidation: React.FC = () => {
  const { goBack } = useNavigation();
  const { params } = useRoute();
  const [isSendingCode, setIsSendingCode] = useState(false);
  const inputOneRef = useRef<TextInput>(null);
  const inputTwoRef = useRef<TextInput>(null);
  const [isResendCodeModalOpen, setIsResendCodeModalOpen] = useState(false);
  const inputThreeRef = useRef<TextInput>(null);
  const inputFourRef = useRef<TextInput>(null);
  const inputFiveRef = useRef<TextInput>(null);
  const { navigate } = useNavigation();
  const { email } = params as RegisterValidationParams;
  const {
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<VerifyCodeFormInput>({
    resolver: zodResolver(verifyCodeValidationSchema),
    defaultValues: {
      code: "",
      email: email || "",
    },
  });

  function handleOpenResendCodeModal() {
    setIsResendCodeModalOpen(true);
  }

  function handleCloseResendCodeModal() {
    setIsResendCodeModalOpen(false);
  }

  const handleVerify = handleSubmit(async values => {
    try {
      const { errors } = await apolloClient.mutate<
        VerifyPhoneNumberResponse,
        VerifyPhoneNumberVariables
      >({
        mutation: verifyPhoneNumberMutation,
        fetchPolicy: "no-cache",
        variables: values,
      });
      const errorMessage = errors?.at(0)?.message;
      if (errorMessage) {
        return alert("error", errorMessage);
      }
      navigate("Login");
    } catch (error) {
      console.error(error);
    }
  });

  async function handleResendCode() {
    setIsSendingCode(true);
    try {
      const { errors } = await apolloClient.mutate<
        ResendVerificationCodeResponse,
        ResendVerificationCodeVariables
      >({
        mutation: resendVerificationCodeMutation,
        fetchPolicy: "no-cache",
        variables: {
          email: getValues("email"),
        },
      });
      const errorMessage = errors?.at(0)?.message;
      if (errorMessage) {
        return alert("error", errorMessage);
      }
      handleOpenResendCodeModal();
    } catch (error) {
      console.error(error);
    } finally {
      setIsSendingCode(false);
    }
  }

  return (
    <Layout>
      <View className="p-4">
        <WarningCard
          text="Outro código será enviado"
          description="fique atento ao SMS, o código será válido por 10 minutos."
          isOpen={isResendCodeModalOpen}
          onCancel={handleCloseResendCodeModal}
          onClose={handleCloseResendCodeModal}
          onConfirm={handleCloseResendCodeModal}
        />
        <TouchableOpacity className="ml-4 mt-14" onPress={goBack}>
          <Ionicons name="chevron-back" size={30} color="white" />
        </TouchableOpacity>
        <View className="items-center justify-center">
          <Text className="font-open-sans-400 text-xl font-normal text-white">
            INSCRIÇÃO
          </Text>
          <Text className="mt-4 text-center font-open-sans-400 text-base font-normal text-white">
            Digite o código que foi recebido por SMS para finalizar o cadastro:
          </Text>
        </View>
        <View className="mt-8">
          {!email && (
            <Controller
              control={control}
              name="email"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View className="mb-4">
                  <InputText
                    value={value || ""}
                    variant="secondary"
                    autoCapitalize="none"
                    onChangeText={onChange}
                    placeholder="Seu email"
                    keyboardType="email-address"
                    placeholderTextColor="#BDBDBD"
                  />
                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />
          )}
          <Controller
            control={control}
            name="code"
            render={({
              field: { onChange, value, onBlur },
              fieldState: { error },
            }) => {
              return (
                <View>
                  <View className="flex-row justify-between">
                    <InputCode
                      value={value?.at(0) || ""}
                      onBlur={onBlur}
                      ref={inputOneRef}
                      maxLength={1}
                      onChangeText={newValue => {
                        if (value.length === 0) {
                          onChange(newValue);
                          inputTwoRef.current?.focus();
                        } else {
                          onChange("");
                        }
                      }}
                    />
                    <InputCode
                      value={value?.at(1) || ""}
                      onBlur={onBlur}
                      ref={inputTwoRef}
                      maxLength={1}
                      onChangeText={newValue => {
                        if (value.length === 1) {
                          onChange(value + newValue);
                          inputThreeRef.current?.focus();
                        } else {
                          onChange(value.substring(0, 1));
                          inputOneRef.current?.focus();
                        }
                      }}
                    />
                    <InputCode
                      value={value?.at(2) || ""}
                      onBlur={onBlur}
                      ref={inputThreeRef}
                      maxLength={1}
                      onChangeText={newValue => {
                        if (value.length === 2) {
                          onChange(value + newValue);
                          inputFourRef.current?.focus();
                        } else {
                          onChange(value.substring(0, 2));
                          inputTwoRef.current?.focus();
                        }
                      }}
                    />
                    <InputCode
                      value={value?.at(3) || ""}
                      onBlur={onBlur}
                      ref={inputFourRef}
                      maxLength={1}
                      onChangeText={newValue => {
                        if (value.length === 3) {
                          onChange(value + newValue);
                          inputFiveRef.current?.focus();
                        } else {
                          onChange(value.substring(0, 3));
                          inputThreeRef.current?.focus();
                        }
                      }}
                    />
                    <InputCode
                      value={value?.at(4) || ""}
                      onBlur={onBlur}
                      ref={inputFiveRef}
                      maxLength={1}
                      onChangeText={newValue => {
                        if (value.length === 4) {
                          onChange(value + newValue);
                        } else {
                          onChange(value.substring(0, 4));
                          inputFourRef.current?.focus();
                        }
                      }}
                    />
                  </View>
                  <ErrorMessage message={error?.message} />
                </View>
              );
            }}
          />
        </View>
        <TouchableOpacity
          className="mt-2 flex-row items-center space-x-2"
          disabled={isSendingCode}
          onPress={handleResendCode}
        >
          <Text className="ml-auto font-inter-500 font-semibold text-white underline">
            Reenviar código
          </Text>
          {isSendingCode && <ActivityIndicator size={16} />}
        </TouchableOpacity>
      </View>
      <View className="mb-5 mt-auto p-4">
        <Button
          text={email ? "Completar inscrição" : "Verificar código"}
          onPress={handleVerify}
          isLoading={isSubmitting}
        />
      </View>
    </Layout>
  );
};
