import { Alert, Platform } from "react-native";
import { toast } from "react-toastify";

export function alert(type: "error" | "success", message: string) {
  if (Platform.OS === "web") {
    toast[type](message);
  } else {
    Alert.alert(type === "error" ? "Erro" : "Sucesso", message);
  }
}
