import { z } from "zod";

export const registerCardValidationSchema = z.object({
  number: z
    .string({ required_error: "Este campo é obrigatório." })
    .transform(number => number.replace(/\D/g, ""))
    .refine(number => {
      const regex = /^\d{16}$/;
      return regex.test(number);
    }, "Este cartão é inválido."),
  holderName: z
    .string({ required_error: "Este campo é obrigatório." })
    .trim()
    .transform(holderName => holderName.replace(/\s+/g, " "))
    .refine(holderName => {
      const regex = /[^\s]{3,}\s(.)+[^\s]{3,}/gi;
      return regex.test(holderName);
    }, "Digite um nome válido."),
  expirationDate: z
    .string({ required_error: "Este campo é obrigatório." })
    .trim()
    .refine(expirationDate => {
      const regex = /^\d{2}\/\d{2}$/;
      return regex.test(expirationDate);
    }, "Digite uma data válida."),
  securityCode: z
    .string({ required_error: "Este campo é obrigatório." })
    .trim()
    .refine(securityCode => {
      const regex = /^\d{3,4}$/;
      return regex.test(securityCode);
    }, "Este código é inválido."),
});

export type RegisterCreditCardFormInput = z.input<
  typeof registerCardValidationSchema
>;
