import { Entypo, Ionicons } from "@expo/vector-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { Fragment, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  ActivityIndicator,
  ImageBackground,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { MyCreditCard } from "~/components/MyCreditCard";
import { QueryFailed } from "~/components/QueryFailed";
import { Select, SelectOption } from "~/components/Select";
import { InputText } from "~/components/TextInput";
import {
  CreateBillingMutationResponse,
  CreateBillingMutationVariables,
  createBillingMutation,
} from "~/graphql/mutations/create-billing";
import { useMyCardsQuery } from "~/hooks/useMyCards";
import { apolloClient } from "~/lib/apollo";
import { BillingTransferAccountType } from "~/types/billing-transfer-account";
import { BillingTransactionType } from "~/types/billling-transaction-type";
import { PixKeyType } from "~/types/pix-key-type";
import { alert } from "~/utils/alert";
import { cpfCpnjMask } from "~/utils/masks/cpfCnpjMask";
import { cpfMask } from "~/utils/masks/cpfMask";
import { currencyMask } from "~/utils/masks/currencyMask";
import { dateMask } from "~/utils/masks/dateMask";
import { phoneMask } from "~/utils/masks/phoneMask";
import {
  CreateBillingFormInput,
  createBillingValidationSchema,
} from "~/validation/create-billing";

const billingTransferTypeOptions: SelectOption[] = [
  { label: "PIX", value: BillingTransactionType.pix },
  { label: "TED", value: BillingTransactionType.ted },
];

const pixTypeOptions: SelectOption[] = [
  { label: "CPF", value: PixKeyType.cpf },
  { label: "CNPJ", value: PixKeyType.cnpj },
  { label: "Email", value: PixKeyType.email },
  { label: "Telefone", value: PixKeyType.phone },
  { label: "Chave aleatória", value: PixKeyType.evp },
];
const accountTypeOptions: SelectOption[] = [
  {
    label: "Conta corrente",
    value: BillingTransferAccountType.checking_account,
  },
  {
    label: "Conta poupança",
    value: BillingTransferAccountType.savings_account,
  },
  { label: "Conta salário", value: BillingTransferAccountType.salary_account },
  {
    label: "Conta de pagamento",
    value: BillingTransferAccountType.payment_account,
  },
];

export const CreateBilling: React.FC = () => {
  const { goBack, navigate } = useNavigation();
  const { data, loading, refetch, error } = useMyCardsQuery();
  const {
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CreateBillingFormInput>({
    resolver: zodResolver(createBillingValidationSchema),
  });

  const transactionType = watch("transactionType");

  const handleCreateBilling = handleSubmit(async values => {
    try {
      const response = await apolloClient.mutate<
        CreateBillingMutationResponse,
        CreateBillingMutationVariables
      >({
        mutation: createBillingMutation,
        variables: {
          input: {
            name: values.name,
            cardId: values.cardId,
            price: Number(values.price),
            dueDate: values.paymentDate,
            description: values.description,
            recurrenceMonths: values.recurrenceMonths,
            transfer: {
              favoredName: values.favoredName,
              cpfCnpj: values.cpfCnpj,
              pix:
                values.transactionType === BillingTransactionType.pix
                  ? {
                      key: values.pixKey!,
                      type: values.pixKeyType!,
                    }
                  : undefined,
              ted:
                values.transactionType === BillingTransactionType.ted
                  ? {
                      compe: values.compe!,
                      branch: values.branch!,
                      account: values.account!,
                      accountType: values.accountType!,
                    }
                  : undefined,
            },
          },
        },
      });

      if (response.errors) {
        return alert("error", response.errors[0].message);
      }

      navigate("RegisterBillingSuccess");
    } catch (error) {
      alert(
        "error",
        "Não foi possível concluir o cadastro. Tente novamente mais tarde.",
      );

      console.log(JSON.stringify(error, null, 2));
    }
  });

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  const pixKeyType = watch("pixKeyType");

  return (
    <ImageBackground
      className="h-[375px] flex-1 bg-white"
      source={require("assets/gradient-eleva-2.png")}
      resizeMode="cover"
    >
      <TouchableOpacity className="ml-4 mt-16" onPress={goBack}>
        <Ionicons name="chevron-back" size={30} color="#0789E5" />
      </TouchableOpacity>

      <ScrollView contentContainerStyle={{ padding: 24 }}>
        <View className="flex-row items-center space-x-2">
          <Entypo name="map" size={50} color="#00000060" />

          <Text className="font-open-sans-600 text-sm text-black opacity-60">
            CADASTRAR CONTA
          </Text>
        </View>

        <View className="mt-4 flex-1 space-y-2">
          <Text className="font-open-sans-600 text-[#505050]">NOME *</Text>

          <Controller
            control={control}
            name="name"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View className="mt-2">
                <InputText
                  value={value || ""}
                  variant="secondary"
                  onChangeText={onChange}
                  placeholder="Ex.: Aluguel do apartamento"
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />

          <Text className="font-open-sans-600 text-[#505050]">
            DATA DE PAGAMENTO *
          </Text>

          <Controller
            control={control}
            name="paymentDate"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View className="mt-2">
                <InputText
                  value={value || ""}
                  variant="secondary"
                  onChangeText={text => onChange(dateMask(text))}
                  placeholder="00/00/0000"
                />

                <ErrorMessage message={error?.message} />
              </View>
            )}
          />

          <Text className="font-open-sans-600 text-[#505050]">VALOR *</Text>

          <Controller
            control={control}
            name="price"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View className="mt-2">
                <InputText
                  prefix="R$"
                  variant="secondary"
                  keyboardType="numeric"
                  value={value?.toString() || ""}
                  onChangeText={text => onChange(currencyMask(text.toString()))}
                />

                <ErrorMessage message={error?.message} />
              </View>
            )}
          />

          <Text className="font-open-sans-600 text-[#505050]">
            REGRA DE RECORRÊNCIA
          </Text>

          <View className="flex-col items-start">
            <View className="flex-row items-center space-x-2">
              <Text className="font-open-sans-400 text-[#505050]">
                Repetir por:
              </Text>

              <Controller
                control={control}
                name="recurrenceMonths"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <View className="p-1.5">
                    <InputText
                      variant="secondary"
                      keyboardType="numeric"
                      value={value?.toString() || ""}
                      onChangeText={
                        onChange as unknown as (text: string) => void
                      }
                    />
                    <ErrorMessage message={error?.message} />
                  </View>
                )}
              />
              <Text className="font-open-sans-400 text-[#505050]">meses</Text>
            </View>
          </View>

          <Text className="mt-4 font-open-sans-600 text-[#505050]">
            CARTÃO DE CRÉDITO *
          </Text>

          <View className="mt-4 flex-1">
            {loading ? (
              <View>
                <ActivityIndicator size={40} />
              </View>
            ) : !data || error ? (
              <QueryFailed refetch={refetch} />
            ) : data.myCards.length === 0 ? (
              <View>
                <View className="mb-2 flex-row items-center justify-center p-4">
                  <Text className="text-center font-inter-500 text-sm font-semibold text-black opacity-80">
                    Nenhum cartão cadastrado.{" "}
                  </Text>

                  <TouchableOpacity onPress={() => navigate("CardsStack")}>
                    <Text className="font-inter-700 text-sm font-semibold text-red-500 underline opacity-80">
                      Clique aqui
                    </Text>
                  </TouchableOpacity>

                  <Text className="font-inter-500 text-sm font-semibold text-black opacity-80">
                    {" "}
                    para cadastrar.
                  </Text>
                </View>
              </View>
            ) : (
              <Controller
                control={control}
                name="cardId"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <View className="mb-2 mt-1">
                    {data.myCards.map((card, index, cards) => (
                      <TouchableOpacity
                        key={card.id}
                        onPress={() => onChange(card.id)}
                        className={
                          index !== cards.length - 1 ? "mb-4" : undefined
                        }
                      >
                        <MyCreditCard
                          holderName={card.holderName}
                          cardNumber={card.number}
                          date={card.expirationDate}
                          isSelected={card.id === value}
                          brand={card.brand || "N/A"}
                        />
                      </TouchableOpacity>
                    ))}

                    <ErrorMessage message={error?.message} />
                  </View>
                )}
              />
            )}

            <Text className="font-open-sans-600 text-[#505050]">
              TIPO DE TRANSAÇÃO *
            </Text>

            <Controller
              control={control}
              name="transactionType"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View className="mt-2">
                  <Select
                    onSelect={onChange as (text: string) => void}
                    options={billingTransferTypeOptions}
                    selectedOptionValue={value}
                    placeholder="ESCOLHA UM TIPO"
                  />

                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />

            <Text className="mt-2 font-open-sans-600 text-[#505050]">
              DADOS DA CONTA
            </Text>

            <Controller
              control={control}
              name="favoredName"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View>
                  <InputText
                    className="mt-2"
                    value={value || ""}
                    variant="secondary"
                    onChangeText={onChange}
                    placeholder="NOME DO FAVORECIDO"
                  />

                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />

            <Controller
              control={control}
              name="cpfCnpj"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View>
                  <InputText
                    maxLength={18}
                    className="mt-2"
                    value={value || ""}
                    variant="secondary"
                    placeholder="CPF/CNPJ DO FAVORECIDO"
                    onChangeText={text => onChange(cpfCpnjMask(text))}
                  />

                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />

            {transactionType === BillingTransactionType.pix && (
              <Fragment>
                <Controller
                  control={control}
                  name="pixKeyType"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <View className="mt-2">
                      <Select
                        options={pixTypeOptions}
                        placeholder="TIPO DA CHAVE PIX"
                        selectedOptionValue={value || null}
                        onSelect={value => {
                          setValue("pixKey", "");
                          onChange(value as PixKeyType);
                        }}
                      />

                      <ErrorMessage message={error?.message} />
                    </View>
                  )}
                />

                <Controller
                  control={control}
                  name="pixKey"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <View className="mt-2">
                      <InputText
                        value={value || ""}
                        variant="secondary"
                        placeholder="DIGITE A CHAVE PIX"
                        keyboardType={
                          pixKeyType === PixKeyType.email
                            ? "email-address"
                            : undefined
                        }
                        onChangeText={text => {
                          if (pixKeyType === PixKeyType.cpf) {
                            text = cpfMask(text);
                          } else if (pixKeyType === PixKeyType.cnpj) {
                            text = cpfCpnjMask(text);
                          } else if (pixKeyType === PixKeyType.phone) {
                            text = phoneMask(text);
                          }

                          onChange(text);
                        }}
                      />

                      <ErrorMessage message={error?.message} />
                    </View>
                  )}
                />
              </Fragment>
            )}

            {/* SE FOR TED */}
            {transactionType === BillingTransactionType.ted && (
              <Fragment>
                <Controller
                  control={control}
                  name="compe"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <View className="mt-2">
                      <InputText
                        maxLength={3}
                        value={value || ""}
                        variant="secondary"
                        keyboardType="numeric"
                        onChangeText={onChange}
                        placeholder="CÓDIGO DO BANCO  EX.: 260 (Nubank)"
                      />

                      <ErrorMessage message={error?.message} />
                    </View>
                  )}
                />

                <Controller
                  control={control}
                  name="branch"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <View className="mt-2">
                      <InputText
                        maxLength={10}
                        value={value || ""}
                        variant="secondary"
                        placeholder="AGÊNCIA"
                        keyboardType="numeric"
                        onChangeText={onChange}
                      />

                      <ErrorMessage message={error?.message} />
                    </View>
                  )}
                />

                <Controller
                  control={control}
                  name="account"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <View className="mt-2">
                      <InputText
                        maxLength={20}
                        value={value || ""}
                        variant="secondary"
                        placeholder="CONTA"
                        keyboardType="numeric"
                        onChangeText={onChange}
                      />

                      <ErrorMessage message={error?.message} />
                    </View>
                  )}
                />

                <Controller
                  control={control}
                  name="accountType"
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <View className="mt-2">
                      <Select
                        placeholder="TIPO DE CONTA"
                        options={accountTypeOptions}
                        selectedOptionValue={value || null}
                        onSelect={onChange as (text: string) => void}
                      />

                      <ErrorMessage message={error?.message} />
                    </View>
                  )}
                />
              </Fragment>
            )}

            <Text className="mt-2 font-open-sans-600 text-[#505050]">
              DESCRIÇÃO *
            </Text>

            <Controller
              control={control}
              name="description"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View className="mt-2">
                  <InputText
                    multiline
                    placeholder="..."
                    value={value || ""}
                    variant="secondary"
                    onChangeText={onChange}
                  />

                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />
          </View>

          <View className="mt-10">
            <Button
              text="Adicionar"
              isLoading={isSubmitting}
              onPress={handleCreateBilling}
            />
          </View>
        </View>
      </ScrollView>
    </ImageBackground>
  );
};
