import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  MyNotificationsQueryResponse,
  myNotificationsQuery,
} from "~/graphql/queries/my-notifications";

export function useMyNotificationsQuery(
  options?: QueryHookOptions<MyNotificationsQueryResponse>,
) {
  return useQuery<MyNotificationsQueryResponse>(myNotificationsQuery, options);
}
