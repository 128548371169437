import { Text, TouchableOpacity, View } from "react-native";
import { SvgProps } from "react-native-svg";
import { SvgIcon } from "./SvgIcon";

interface RegisterAccountListProps {
  icon: React.FC<SvgProps>;
  text: string;
}

export const RegisterAccountList: React.FC<RegisterAccountListProps> = ({
  icon: Icon,
  text,
}) => {
  return (
    <View className="items-center justify-center">
      <TouchableOpacity className="items-center justify-center">
        <SvgIcon component={Icon} />
      </TouchableOpacity>
      <Text className="text-center font-open-sans-600 text-black opacity-60">
        {text}
      </Text>
    </View>
  );
};
