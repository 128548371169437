import classNames from "classnames";
import dayjs from "dayjs";
import { Text, View } from "react-native";
import { MyPointsHistoryResponse } from "~/graphql/queries/my-points-history";
import { dateLabelMap } from "~/utils/constants";

interface MyPointsCardProps {
  history: MyPointsHistoryResponse["myPointsHistory"]["history"][number];
}

export const MyPointsCard: React.FC<MyPointsCardProps> = ({ history }) => {
  const date = dayjs(history.date);
  return (
    <View>
      <View className="flex-row space-x-2">
        <View>
          <View className="flex-row items-center">
            <View className="items-center">
              <Text className="font-roboto-mono-500 text-lg font-semibold text-[#333333]">
                {date.format("DD")}
              </Text>

              <Text className="font-roboto-mono-500 text-[#333333]">
                {dateLabelMap[date.format("MM")]}
              </Text>
            </View>

            <View className="absolute left-[26px] z-10 aspect-square w-4 items-center justify-center rounded-full bg-black">
              <View className="aspect-square w-2 rounded-full bg-[#00B2FF]" />
            </View>
          </View>
        </View>

        <View className="relative -z-10 h-full w-0.5 bg-black" />

        <View className="flex-1">
          <View>
            <View className="ml-2 h-[50px] flex-row items-center justify-center space-x-1 rounded-lg bg-[#00B2FF] px-2">
              <View className="absolute -left-1 top-4 h-[15px] w-[15px] rotate-45 bg-[#00B2FF]" />

              <Text className="font-roboto-mono-500 text-xl font-medium text-white">
                {history.pointsAtEndOfDay.toLocaleString("pt-BR")}
              </Text>

              <Text className="font-open-sans-600 text-xs font-medium uppercase text-[#505050]">
                pontos de saldo
              </Text>
            </View>
          </View>

          {history.logs.map(log => {
            const isNegative = log.points < 0;

            return (
              <View key={log.id} className="mb-6 mt-2 flex-row space-x-2">
                <Text
                  className={classNames(
                    "font-roboto-mono-500 text-base font-medium",
                    isNegative ? "text-[#EE4D1A]" : "text-[#0085FF]",
                  )}
                >
                  {!isNegative && "+"}
                  {log.points.toLocaleString("pt-BR")}
                </Text>

                <View className="flex-1">
                  <Text className="font-open-sans-600 font-semibold text-[#505050]">
                    {isNegative ? "Transferência de pontos" : "Conta paga"}
                  </Text>

                  <Text
                    numberOfLines={3}
                    className="font-open-sans-400 font-normal text-[#505050]"
                  >
                    {log.description}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>
      </View>
    </View>
  );
};
