import { gql } from "@apollo/client";

export interface MyCardsQueryResponse {
  myCards: Array<{
    id: string;
    holderName: string;
    expirationDate: string;
    number: string;
    brand: string | null;
  }>;
}

export const myCardsQuery = gql`
  query MyCards {
    myCards {
      id
      holderName
      expirationDate
      number
      brand
    }
  }
`;
