import { Ionicons } from "@expo/vector-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigation } from "@react-navigation/native";
import { Controller, useForm } from "react-hook-form";
import { ScrollView, Text, TouchableOpacity, View } from "react-native";
import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { Layout } from "~/components/Layout";
import { PasswordInput } from "~/components/PasswordInput";
import { InputText } from "~/components/TextInput";
import { cpfMask } from "~/utils/masks/cpfMask";
import { dateMask } from "~/utils/masks/dateMask";
import { phoneMask } from "~/utils/masks/phoneMask";
import {
  RegisterFormInput,
  registerValidationSchema,
} from "~/validation/register";

export const Register: React.FC = () => {
  const { goBack, navigate } = useNavigation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<RegisterFormInput>({
    resolver: zodResolver(registerValidationSchema),
  });

  const handleRegister = handleSubmit(async values => {
    navigate("RegisterStepTwo", values);
  });

  return (
    <Layout>
      <ScrollView contentContainerStyle={{ paddingHorizontal: 24 }}>
        <TouchableOpacity className="ml-4 mt-24" onPress={goBack}>
          <Ionicons name="chevron-back" size={30} color="white" />
        </TouchableOpacity>
        <View className="items-center justify-center">
          <Text className="font-open-sans-400 text-xl font-normal text-white">
            INSCRIÇÃO
          </Text>
        </View>
        <View className="mt-8 p-4">
          <Text className="font-open-sans-600 text-sm font-semibold text-[#505050]">
            DADOS PESSOAIS
          </Text>
          <Controller
            control={control}
            name="name"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View>
                <InputText
                  className="mt-4"
                  variant="secondary"
                  value={value || ""}
                  placeholder="Nome e sobrenome"
                  onChangeText={onChange}
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View>
                <InputText
                  className="mt-4"
                  value={value || ""}
                  variant="secondary"
                  placeholder="E-mail"
                  onChangeText={onChange}
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />
          <Controller
            control={control}
            name="cpf"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View>
                <InputText
                  maxLength={14}
                  className="mt-4"
                  placeholder="CPF"
                  value={value || ""}
                  variant="secondary"
                  onChangeText={text => {
                    onChange(cpfMask(text));
                  }}
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />
          <Controller
            control={control}
            name="birthDate"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View>
                <InputText
                  maxLength={10}
                  className="mt-4"
                  variant="secondary"
                  value={value || ""}
                  placeholder="Data de nascimento"
                  onChangeText={text => onChange(dateMask(text))}
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View>
                <InputText
                  maxLength={16}
                  className="mt-4"
                  value={value || ""}
                  variant="secondary"
                  placeholder="Número de telefone"
                  onChangeText={text => {
                    onChange(phoneMask(text));
                  }}
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />

          <Controller
            control={control}
            name="password"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View>
                <PasswordInput
                  className="mt-4"
                  value={value || ""}
                  variant="secondary"
                  placeholder="Senha"
                  onChangeText={onChange}
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />
          <Controller
            control={control}
            name="passwordConfirmation"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View>
                <PasswordInput
                  className="mt-4"
                  value={value || ""}
                  variant="secondary"
                  placeholder="Confirme a senha"
                  onChangeText={onChange}
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />

          <Button
            text="Continuar"
            isLoading={isSubmitting}
            className="mt-16"
            onPress={handleRegister}
          />
        </View>
      </ScrollView>
    </Layout>
  );
};
