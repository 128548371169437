import classNames from "classnames";
import dayjs from "dayjs";
import { Platform, Text, View } from "react-native";
import type { MyBillingsQueryResponse } from "~/graphql/queries/my-billings";
import { dateLabelMap } from "~/utils/constants";
import { formatCurrency } from "~/utils/formatCurrency";

interface BillingCardProps {
  billing: MyBillingsQueryResponse["myBillings"]["billings"][number];
}

export const BillingCard: React.FC<BillingCardProps> = ({ billing }) => {
  const date = dayjs(billing.dueDate);
  const year = date.format("YYYY");
  const month = date.format("MM");
  const day = date.format("DD");

  return (
    <View
      style={{ elevation: 3 }}
      className={classNames(
        "w-full items-start justify-center rounded-lg bg-white p-4",
        Platform.OS === "web" && "shadow",
      )}
    >
      <View className="w-full flex-row justify-between">
        <View className="mr-2 flex-col">
          <Text className="font-open-sans-600 font-semibold text-[#0789E5]">
            {billing.name}
          </Text>

          <Text
            className="mb-2 mt-1.5 font-roboto-mono-500 text-[#CCCCCC]"
            numberOfLines={3}
          >
            {billing.description}
          </Text>

          {typeof billing.recurrenceMonths === "number" &&
            typeof billing.recurrenceNumber === "number" && (
              <Text className="mb-1 mt-auto font-open-sans-400 text-xs text-[#CCCCCC]">
                Fatura {billing.recurrenceNumber} de {billing.recurrenceMonths}
              </Text>
            )}

          <Text className="text-justify font-open-sans-400 text-[#999999]">
            {formatCurrency(billing.price)}
          </Text>
        </View>

        <View className="items-center justify-center">
          <Text className="font-roboto-mono-300 text-xs text-[#CCCCCC]">
            {year}
          </Text>

          <Text className="font-roboto-mono-300 text-lg uppercase text-[#CCCCCC]">
            {dateLabelMap[month]}
          </Text>

          <Text className="font-roboto-mono-400 text-3xl text-[#CCCCCC]">
            {day}
          </Text>
        </View>
      </View>
    </View>
  );
};
