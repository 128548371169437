import { useFocusEffect } from "@react-navigation/native";
import dayjs from "dayjs";
import { Fragment, useCallback, useEffect, useState } from "react";
import { ActivityIndicator, FlatList, Text, View } from "react-native";
import { Layout } from "~/components/Layout";
import { NotificationCard } from "~/components/NotificationCard";
import { PaymentCards } from "~/components/PaymentCards";
import { QueryFailed } from "~/components/QueryFailed";
import { useAuth } from "~/hooks/useAuth";
import { useMyBillingsQuery } from "~/hooks/useMyBillings";
import { useMyNotificationsQuery } from "~/hooks/useMyNotifications";

function getGreeting(): string {
  const currentHour = new Date().getHours();

  if (currentHour >= 6 && currentHour < 12) {
    return "BOM DIA";
  }

  if (currentHour >= 12 && currentHour < 18) {
    return "BOA TARDE";
  }

  return "BOA NOITE";
}

export const Home: React.FC = () => {
  const { user } = useAuth();
  const [greeting, setGreeting] = useState(getGreeting);
  const { data, loading, error, refetch } = useMyNotificationsQuery();
  const { data: billingsData, refetch: refetchBillings } = useMyBillingsQuery({
    variables: {
      filters: {
        isCharged: false,
        dueDate: {
          lte: dayjs().endOf("month").format("YYYY-MM-DD"),
          gte: dayjs().startOf("month").format("YYYY-MM-DD"),
        },
      },
    },
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setGreeting(getGreeting());
    }, 1000); // 1 segundo

    return () => clearInterval(interval);
  }, []);

  useFocusEffect(
    useCallback(() => {
      refetch();
      refetchBillings();
    }, [refetch, refetchBillings]),
  );

  return (
    <Layout>
      <View className="flex-1">
        <View className="mt-16 items-center">
          <Text className="font-open-sans-400 text-sm font-normal text-white">
            {greeting}, {user?.name}
          </Text>

          <View className="flex-row">
            <Text className="font-inter-400 text-sm font-normal uppercase text-white">
              Você possui{" "}
            </Text>

            <Text className="font-inter-700 text-sm font-bold uppercase text-white">
              {billingsData?.myBillings.totalCount ?? 0}
            </Text>

            <Text className="font-inter-400 text-sm font-normal uppercase text-white">
              {" "}
              pagamentos agendado
              {billingsData?.myBillings.totalCount !== 1 && "s"}
            </Text>
          </View>

          <View className="mt-4">
            <PaymentCards isCharged={false} />
          </View>
        </View>

        <View className="mt-16 flex-1 bg-[#F5F5F5] pt-3">
          {loading ? (
            <View>
              <ActivityIndicator size={40} />
            </View>
          ) : error || !data ? (
            <QueryFailed refetch={refetch} />
          ) : (
            <Fragment>
              <Text className="mb-4 text-center font-open-sans-700 text-[#999999]">
                Você possui {data.myNotifications.notifications.length} aviso
                {data.myNotifications.notifications.length !== 1 && "s"}
              </Text>

              <FlatList
                data={data.myNotifications.notifications}
                keyExtractor={notification => notification.id}
                contentContainerStyle={{
                  paddingBottom: 24,
                  paddingHorizontal: 24,
                }}
                ItemSeparatorComponent={() => <View className="my-1" />}
                renderItem={({ item }) => (
                  <NotificationCard title={item.title} message={item.message} />
                )}
              />
            </Fragment>
          )}
        </View>
      </View>
    </Layout>
  );
};
