import { Feather } from "@expo/vector-icons";
import { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { InputText, InputTextProps } from "./TextInput";

interface PasswordInputProps extends InputTextProps {}

export const PasswordInput: React.FC<PasswordInputProps> = props => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <View className="relative">
      <InputText
        variant="third"
        {...props}
        placeholderTextColor="#999999"
        secureTextEntry={!showPassword}
      />

      <TouchableOpacity
        onPress={() => setShowPassword(!showPassword)}
        className="absolute bottom-0 right-0 h-[40px] items-center justify-center border-l-2 border-[#BDBDBD] px-1.5"
      >
        <Feather
          name={showPassword ? "eye-off" : "eye"}
          size={24}
          color="#BDBDBD"
          className="object-contain"
        />
      </TouchableOpacity>
    </View>
  );
};
