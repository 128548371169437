import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { parsePhoneNumber } from "libphonenumber-js";
import { z } from "zod";
import { isValidCpf } from "~/utils/isValidCpf";
import { DateConverter } from "~/utils/masks/DateConverter";
import { passwordValidationSchema } from "./reusable/password-validation";

dayjs.extend(customParseFormat);

export const registerValidationSchema = z
  .object({
    name: z
      .string({ required_error: "Este campo é obrigatório." })
      .min(1, "Este campo é obrigatório.")
      .trim(),
    email: z
      .string({ required_error: "Este campo é obrigatório." })
      .email("Digite um e-mail válido.")
      .trim(),
    cpf: z
      .string({ required_error: "Este campo é obrigatório." })
      .min(1, "Este campo é obrigatório.")
      .trim()
      .refine(isValidCpf, "Este CPF é inválido."),
    birthDate: z
      .string({ required_error: "Este campo é obrigatório." })
      .trim()
      .min(10, "Digite uma data válida.")
      .transform(DateConverter.fromBrazilianToUniversal)
      .refine(birthDate => {
        return dayjs(birthDate, "YYYY-MM-DD", true).isValid();
      }, "Data inválida"),
    phoneNumber: z
      .string({ required_error: "Este campo é obrigatório." })
      .trim()
      .refine(phoneNumber => {
        try {
          return parsePhoneNumber(phoneNumber, "BR").isValid();
        } catch {
          return false;
        }
      }, "O número de telefone deve ter de 8 a 9 dígitos e incluir DDD.")
      .transform(phoneNumber => {
        return parsePhoneNumber(phoneNumber, "BR").number;
      }),
    password: passwordValidationSchema,
    passwordConfirmation: z.string().optional(),
  })
  .superRefine(({ password, passwordConfirmation }, context) => {
    if (password !== passwordConfirmation) {
      context.addIssue({
        code: "custom",
        message: "A confirmação está incorreta",
        path: ["passwordConfirmation"],
      });
    }
  });

export type RegisterFormInput = z.input<typeof registerValidationSchema>;
