import { gql } from "@apollo/client";

export interface MyNotificationsQueryResponse {
  myNotifications: {
    notifications: Array<{
      id: string;
      title: string;
      message: string;
    }>;
  };
}

export const myNotificationsQuery = gql`
  query MyNotifications {
    myNotifications {
      notifications {
        id
        title
        message
      }
    }
  }
`;
