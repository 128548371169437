import { AntDesign, SimpleLineIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import dayjs from "dayjs";
// import * as ImagePicker from "expo-image-picker";
import { useCallback, useState } from "react";
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import HomeIcon from "~/assets/icons/home-eleva.svg";
import MessageIcon from "~/assets/icons/message-profile-icon.svg";
import ProfileIcon from "~/assets/icons/profile-eleva.svg";
import { SvgIcon } from "~/components/SvgIcon";
import { useAuth } from "~/hooks/useAuth";
import { phoneMask } from "~/utils/masks/phoneMask";
// import { uploadImages } from "~/utils/uploadImages";

export const Profile: React.FC = () => {
  const { logout, user, fetchUser } = useAuth();
  const [imageURL, _setImageURL] = useState("");
  const [isUploadingImage, _setIsUploadingImage] = useState(false);

  useFocusEffect(
    useCallback(() => {
      fetchUser();
    }, [fetchUser]),
  );

  // const pickImage = async (type: "camera" | "gallery" | "delete") => {
  //   setIsUploadingImage(true);
  //   try {
  //     let imageId: string | null = null;
  //     if (type !== "delete") {
  //       const result = await ImagePicker[
  //         type === "camera" ? "launchCameraAsync" : "launchImageLibraryAsync"
  //       ]({
  //         mediaTypes: ImagePicker.MediaTypeOptions.All,
  //         allowsMultipleSelection: true,
  //         aspect: [16, 9],
  //         quality: 1,
  //       });

  //       if (result.canceled) {
  //         return;
  //       }
  //       const uploadedImageIds = await uploadImages([result.assets[0].uri]);
  //       imageId = uploadedImageIds[0];
  //     }

  //     // await apolloClient.mutate<
  //     //   UpdateUserImageResponse,
  //     //   UpdateUserImageVariables
  //     // >({
  //     //   mutation: updateUserImageMutation,
  //     //   variables: {
  //     //     userId: user!.id,
  //     //     imageId: imageId,
  //     //   },
  //     // });
  //     // await refetch();
  //   } catch (error) {
  //     console.error(JSON.stringify(error, null, 2));
  //   } finally {
  //     setIsUploadingImage(false);
  //   }
  // };

  return (
    <ImageBackground
      className="h-[354px] flex-1 bg-white"
      source={require("assets/gradient-eleva-3.png")}
      resizeMode="cover"
    >
      <View className="mt-8 flex-1 px-6 pt-6">
        <View className="flex-col items-center">
          <View className="aspect-square w-[120px] rounded-full">
            {isUploadingImage ? (
              <View className="justify-content items-center">
                <ActivityIndicator size={30} color="#FF6600" />
              </View>
            ) : (
              <View className="relative h-full w-full items-center justify-center rounded-full border-2 border-white bg-gray-200 opacity-80">
                {imageURL ? (
                  <Image
                    source={[]}
                    className="h-full w-full rounded-full object-cover"
                    resizeMode="cover"
                  />
                ) : (
                  <AntDesign name="user" size={60} color="white" />
                )}
                {/* <EditProfileButton pickImage={pickImage} /> */}
              </View>
            )}
          </View>
        </View>
        <ScrollView
          showsVerticalScrollIndicator={false}
          className="mt-12"
          contentContainerStyle={{ paddingBottom: 24 }}
        >
          <View className="flex-row space-x-4">
            <SvgIcon component={ProfileIcon} />
            <View className="flex-col ">
              <Text className="items-start font-open-sans-600 text-base font-semibold text-[#505050]">
                {user?.name}
              </Text>
              <Text className="mt-2 font-open-sans-400 text-sm text-[#505050]">
                CPF
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                {user?.cpf}
              </Text>
              {/* <Text className="font-open-sans-400 mt-2 text-sm text-[#505050]">
                Profissão
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                Técnico Administrativo
              </Text> */}
              <Text className="mt-2 font-open-sans-400 text-sm text-[#505050]">
                Nascimento
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                {dayjs(user?.birthDate).format("DD/MM/YYYY")}
              </Text>
            </View>
          </View>
          <View className="mt-12 flex-row space-x-4">
            <SvgIcon component={MessageIcon} />
            <View className="flex-col ">
              <Text className="font-open-sans-600 text-base font-semibold text-[#505050]">
                Contato
              </Text>
              <Text className="mt-2 font-open-sans-400 text-sm text-[#505050]">
                Celular
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                {phoneMask(user!.phoneNumber)}
              </Text>
              <Text className="mt-2 font-open-sans-400 text-sm text-[#505050]">
                E-mail
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                {user?.email}
              </Text>
              {/* <Text className="font-open-sans-400 mt-2 text-sm text-[#505050]">
                Whatsapp
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                +551193456-7890
              </Text> */}
            </View>
          </View>
          <View className="mt-12 flex-row space-x-4">
            <SvgIcon component={HomeIcon} />
            <View className="flex-col">
              <Text className="font-open-sans-600 text-base font-semibold text-[#505050]">
                Endereço residencial
              </Text>
              <Text className="mt-2 font-open-sans-400 text-sm text-[#505050]">
                Logradouro
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                {user?.homeAddress?.street || "-"}, {user?.homeAddress?.number}
              </Text>
              <Text className="mt-2 font-open-sans-400 text-sm text-[#505050]">
                Complemento
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                {user?.homeAddress?.complement || "-"}
              </Text>
              <Text className="mt-2 font-open-sans-400 text-sm text-[#505050]">
                Bairro
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                {user?.homeAddress?.district || "-"}
              </Text>
              <Text className="mt-2 font-open-sans-400 text-sm text-[#505050]">
                Cidade
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                {user?.homeAddress?.city || "-"} /{" "}
                {user?.homeAddress?.state || "-"}
              </Text>
              <Text className="mt-2 font-open-sans-400 text-sm text-[#505050]">
                CEP
              </Text>
              <Text className="font-open-sans-400 text-base font-medium text-[#505050]">
                {user?.homeAddress?.zipcode || "-"}
              </Text>
            </View>
          </View>
          <TouchableOpacity
            className="mt-12 flex-row space-x-4"
            onPress={logout}
          >
            <View className="ml-2">
              <SimpleLineIcons name="logout" size={28} color="#DFBD63" />
            </View>
            <Text className="font-open-sans-600 text-base font-semibold text-[#505050]">
              Sair
            </Text>
          </TouchableOpacity>
        </ScrollView>
      </View>
    </ImageBackground>
  );
};
