import {
  Entypo,
  Feather,
  FontAwesome,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { ActivityIndicator, View } from "react-native";
import { useAuth } from "~/hooks/useAuth";
import { Home } from "~/pages/Home";
import { MyPoints } from "~/pages/MyPoints";
import { Profile } from "~/pages/Profile";
import { UserRole } from "~/types/UserRole";
import { AuthStack } from "./AuthStack";
import { CardsStackRoutes } from "./CardsStack";
import { MyBillingsStackRoutes } from "./MyBillingsStack";
import { SubsidiesStackRoutes } from "./SubsidiesStackRoutes";

const Tab = createBottomTabNavigator();

export const Routes: React.FC = () => {
  const { isInitialLoad, user } = useAuth();

  if (isInitialLoad) {
    return (
      <View className="flex-1 items-center justify-center">
        <ActivityIndicator size={48} />
      </View>
    );
  }

  if (!user) {
    return <AuthStack />;
  }

  return (
    <Tab.Navigator
      initialRouteName="HomeStack"
      screenOptions={{
        headerShown: false,
        tabBarActiveTintColor: "black",
        tabBarStyle: {
          height: 94,
          borderTopWidth: 0,
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          backgroundColor: "white",
        },
        tabBarLabelStyle: {
          fontSize: 12,
          marginTop: -8,
          marginBottom: 16,
          fontWeight: "400",
          color: "#333333",
        },
      }}
    >
      <Tab.Screen
        name="MyBillsStack"
        component={MyBillingsStackRoutes}
        options={{
          tabBarLabel: "Contas",
          tabBarIcon: ({ color, size }) => (
            <Entypo name="map" size={size} color={color} />
          ),
        }}
      />

      <Tab.Screen
        name="CardsStack"
        component={CardsStackRoutes}
        options={{
          tabBarLabel: "Cartões",
          tabBarIcon: ({ color, size }) => (
            <MaterialCommunityIcons
              name="card-bulleted-outline"
              size={size}
              color={color}
            />
          ),
        }}
      />

      <Tab.Screen
        name="HomeStack"
        component={Home}
        options={{
          tabBarLabel: "Início",
          tabBarIcon: ({ color, size }) => (
            <Entypo name="home" size={size} color={color} />
          ),
        }}
      />

      <Tab.Screen
        name="MyPoints"
        component={MyPoints}
        options={{
          tabBarLabel: "Pontos",
          tabBarIcon: ({ color, size }) => (
            <Feather name="gift" size={size} color={color} />
          ),
        }}
      />

      <Tab.Screen
        name="Profile"
        component={Profile}
        options={{
          tabBarLabel: "Perfil",
          tabBarIcon: ({ color, size }) => (
            <FontAwesome name="user-circle-o" size={size} color={color} />
          ),
        }}
      />

      {user.role === UserRole.Admin && (
        <Tab.Screen
          name="Subsidies"
          component={SubsidiesStackRoutes}
          options={{
            tabBarLabel: "Subsídios",
            tabBarIcon: ({ color, size }) => (
              <MaterialCommunityIcons
                size={size}
                color={color}
                name="account-cash"
              />
            ),
          }}
        />
      )}
    </Tab.Navigator>
  );
};
