export function phoneMask(value: string): string {
  value = value.replace(/\D/g, ""); // remove não númericos
  value = value.substring(0, 11); // limita caracteres

  if (!value) {
    return value;
  }

  if (value.length < 3) {
    return `(${value}`;
  }

  if (value.length < 4) {
    return `(${value.substring(0, 2)}) ${value.substring(2)}`;
  }

  if (value.length < 7) {
    return `(${value.substring(0, 2)}) ${value.substring(2)}`;
  }

  if (value.length < 11) {
    return `(${value.substring(0, 2)}) ${value.substring(
      2,
      6,
    )}-${value.substring(6)}`;
  }

  return `(${value.substring(0, 2)}) ${value.substring(2, 3)} ${value.substring(
    3,
    7,
  )}-${value.substring(7)}`;
}
