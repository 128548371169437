export function dateCardMask(value: string): string {
  value = value.replace(/[^\d\/]/, "");

  if (value.match(/^\d{2}\/\d{3,}$/) !== null) {
    value = `${value.substring(0, 5)}/${value.substring(5)}`;
  } else if (value.match(/^\d{3,}$/) !== null) {
    value = `${value.substring(0, 2)}/${value.substring(2)}`;
  }

  return value.substring(0, 5);
}
