import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import {
  FlatList,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { SvgProps } from "react-native-svg";
import ArrowUp from "~/assets/icons/arrow-up.svg";
import Arrows from "~/assets/icons/arrows-eleva.svg";
import ClockIcon from "~/assets/icons/clock-eleva.svg";
import FileEleva from "~/assets/icons/file-eleva.svg";
import GalleryIcon from "~/assets/icons/gallery-eleva.svg";
import ImpressIcon from "~/assets/icons/impress-eleva.svg";
import InternetIcon from "~/assets/icons/internet-eleva.svg";
import LabelIcon from "~/assets/icons/label-eleva.svg";
import LockedIcon from "~/assets/icons/locked-eleva.svg";
import MessageIcon from "~/assets/icons/message-eleva.svg";
import PregnantIcon from "~/assets/icons/pregnant-eleva.svg";
import RentIcon from "~/assets/icons/rent-eleva.svg";
import SchoolIcon from "~/assets/icons/school-eleva.svg";
import TelephoneIcon from "~/assets/icons/telephone-eleva.svg";
import UserIcon from "~/assets/icons/user-eleva.svg";
import { RegisterAccountList } from "~/components/RegisterAccountList";

interface Card {
  id: string;
  icon: React.FC<SvgProps>;
  text: string;
}

export const RegisterAccount: React.FC = () => {
  const { goBack, navigate } = useNavigation();
  const cards: Card[] = [
    {
      id: "1",
      icon: RentIcon,
      text: "ALUGUEL",
    },
    {
      id: "2",
      icon: SchoolIcon,
      text: "ESCOLA",
    },
    {
      id: "3",
      icon: InternetIcon,
      text: "ESCOLA",
    },
    {
      id: "4",
      icon: LockedIcon,
      text: "ESCOLA",
    },
    {
      id: "5",
      icon: LabelIcon,
      text: "ESCOLA",
    },
    {
      id: "6",
      icon: MessageIcon,
      text: "ESCOLA",
    },
    {
      id: "7",
      icon: ArrowUp,
      text: "ESCOLA",
    },
    {
      id: "8",
      icon: FileEleva,
      text: "ESCOLA",
    },
    {
      id: "9",
      icon: TelephoneIcon,
      text: "ESCOLA",
    },
    {
      id: "10",
      icon: UserIcon,
      text: "ESCOLA",
    },
    {
      id: "11",
      icon: GalleryIcon,
      text: "ESCOLA",
    },
    {
      id: "12",
      icon: Arrows,
      text: "ESCOLA",
    },
    {
      id: "13",
      icon: PregnantIcon,
      text: "ESCOLA",
    },
    {
      id: "14",
      icon: ImpressIcon,
      text: "ESCOLA",
    },
    {
      id: "15",
      icon: ClockIcon,
      text: "ESCOLA",
    },
  ];

  return (
    <ImageBackground
      className="h-[375px] flex-1 bg-white"
      source={require("assets/gradient-eleva-2.png")}
      resizeMode="cover"
    >
      <View className="mt-16 flex-1">
        <Text className="text-center font-open-sans-400 text-sm font-normal uppercase text-black opacity-60">
          CADASTRAR CONTA
        </Text>
        <Text className="items-center p-4 text-center font-open-sans-400 text-sm font-normal uppercase text-black opacity-60">
          ESCOLHA A CLASSIFICAÇÃO QUE MELHOR DESCREVA SUA NOVA CONTA
        </Text>

        <FlatList
          data={cards}
          numColumns={3}
          showsVerticalScrollIndicator={false}
          keyExtractor={card => card.id}
          contentContainerStyle={{
            paddingHorizontal: 24,
            paddingBottom: 10,
            alignItems: "center",
          }}
          ItemSeparatorComponent={() => <View className="my-5" />}
          renderItem={({ item, index }) => (
            <View style={{ marginLeft: (index + 3) % 3 !== 0 ? 8 : 0 }}>
              <RegisterAccountList text={item.text} icon={item.icon} />
            </View>
          )}
        />

        <View className="w-full flex-row justify-around pb-2">
          <TouchableOpacity
            className="flex-row items-center rounded bg-[#EE4D1A] p-2.5"
            onPress={goBack}
          >
            <AntDesign name="left" size={24} color="white" />
            <Text className="font-inter-500 font-medium text-[#FFFFFF]">
              Voltar
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            className="flex-row items-center rounded bg-[#0085FF] p-2.5"
            onPress={() => navigate("CreateBilling")}
          >
            <Text className="font-inter-500 font-medium text-[#FFFFFF]">
              Próx.
            </Text>
            <AntDesign name="right" size={24} color="white" />
          </TouchableOpacity>
        </View>
      </View>
    </ImageBackground>
  );
};
