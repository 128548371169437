import { gql } from "@apollo/client";

export interface VerifyPhoneNumberResponse {
  verifyPhoneNumber: boolean;
}

export interface VerifyPhoneNumberVariables {
  email: string;
  code: string;
}

export const verifyPhoneNumberMutation = gql`
  mutation VerifyPhoneNumber($email: String!, $code: String!) {
    verifyPhoneNumber(email: $email, code: $code)
  }
`;
