import { Ionicons } from "@expo/vector-icons";
import {
  FlatList,
  Dimensions as ReactNativeDimensions,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Dimensions, SelectOption } from "./Select";

const screenHeight = ReactNativeDimensions.get("window").height;

interface SelectDropdownProps {
  onClose: () => void;
  options: SelectOption[];
  dimensions: Dimensions;
  closeOnSelect?: boolean;
  onSelect: (value: SelectOption["value"]) => void;
  isOptionSelected?: (option: SelectOption) => boolean;
}

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  onClose,
  options,
  onSelect,
  dimensions,
  closeOnSelect = true,
  isOptionSelected,
}) => {
  const maxHeight = 300;
  const top = dimensions.y + dimensions.height + 4;
  const isInverted = top + maxHeight > screenHeight;

  return (
    <View
      style={{
        top: isInverted ? dimensions.y - 4 : top,
        left: dimensions.x,
        maxHeight: maxHeight,
        width: dimensions.width,
        transform: isInverted
          ? [
              {
                translateY: -maxHeight,
              },
            ]
          : undefined,
      }}
      className="overflow-hidden rounded-lg bg-white"
    >
      <FlatList
        data={options}
        ListEmptyComponent={() => (
          <View className="w-full bg-white px-4 py-3">
            <Text>Não há mais opções disponíveis!</Text>
          </View>
        )}
        keyExtractor={option => option.value}
        renderItem={({ item }) => {
          const isSelected = isOptionSelected?.(item);
          return (
            <TouchableOpacity
              className="w-full flex-row items-center space-x-2 bg-white px-4 py-3"
              onPress={() => {
                onSelect(item.value);
                if (closeOnSelect) {
                  onClose();
                }
              }}
            >
              <Text className="flex-1">{item.label}</Text>
              {isSelected && (
                <Ionicons name="md-checkmark-sharp" size={24} color="#080B2A" />
              )}
            </TouchableOpacity>
          );
        }}
      />
    </View>
  );
};
