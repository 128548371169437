import {
  ApolloError,
  type ApolloQueryResult,
  type OperationVariables,
} from "@apollo/client";
import { AntDesign } from "@expo/vector-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import classNames from "classnames";
import { Fragment, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Modal,
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import {
  ConsumePointsResponse,
  ConsumePointsVariables,
  consumePointsMutation,
} from "~/graphql/mutations/consume-points";
import { MyPointsHistoryResponse } from "~/graphql/queries/my-points-history";
import { apolloClient } from "~/lib/apollo";
import { AirCompany } from "~/types/AirCompany";
import { alert } from "~/utils/alert";
import { currencyMask } from "~/utils/masks/currencyMask";
import {
  ConsumePointsFormInput,
  consumePointsValidationSchema,
} from "~/validation/consume-points";
import { Button } from "./Button";
import { ErrorMessage } from "./ErrorMessage";
import { Select, SelectOption } from "./Select";
import { InputText } from "./TextInput";

const airCompanyOptions: SelectOption[] = [
  { label: AirCompany.Azul, value: AirCompany.Azul },
  { label: AirCompany.Gol, value: AirCompany.Gol },
  { label: AirCompany.Latam, value: AirCompany.Latam },
];

interface ConsumePointsButtonProps {
  refetch: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<MyPointsHistoryResponse>>;
}

export const ConsumePointsButton: React.FC<ConsumePointsButtonProps> = ({
  refetch,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    reset,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<ConsumePointsFormInput>({
    resolver: zodResolver(consumePointsValidationSchema),
  });

  function handleOpenModal() {
    setIsModalOpen(true);
  }

  function handleCloseModal() {
    setIsModalOpen(false);
  }

  const handleConsumePoints = handleSubmit(async values => {
    try {
      const response = await apolloClient.mutate<
        ConsumePointsResponse,
        ConsumePointsVariables
      >({
        mutation: consumePointsMutation,
        variables: {
          points: Number(values.points),
          airCompany: values.airCompany,
        },
      });

      if (response.errors) {
        return alert("error", response.errors[0].message);
      }

      await refetch();
      reset();
      handleCloseModal();
      alert("success", "Pontos consumidos com sucesso!");
    } catch (error) {
      console.log(error);
      console.log(JSON.stringify(error, null, 2));
      let errorMessage = "Não foi possível consumir os pontos.";

      if (error instanceof ApolloError) {
        errorMessage = error.message;
      }

      alert("error", errorMessage);
    }
  });

  return (
    <Fragment>
      <TouchableOpacity
        onPress={handleOpenModal}
        className="mb-4 mr-2.5 mt-8 flex-row items-center justify-center self-end rounded-full bg-[#0085FF] p-2"
      >
        <Text className="text-center font-inter-500 text-base text-white">
          Consumir pontos
        </Text>
      </TouchableOpacity>

      <Modal
        transparent
        animationType="fade"
        statusBarTranslucent
        visible={isModalOpen}
        onRequestClose={handleCloseModal}
      >
        <Pressable
          className="flex-1 bg-black/30"
          onPress={event => {
            if (event.target === event.currentTarget) {
              handleCloseModal();
            }
          }}
        >
          <View
            style={{ elevation: 8 }}
            className={classNames(
              "absolute right-2.5 w-[276px] rounded-lg bg-white p-2.5",
              Platform.OS === "web" ? "top-20 shadow-lg" : "top-24 mt-1.5",
            )}
          >
            <TouchableOpacity
              onPress={handleCloseModal}
              className="mb-2.5 aspect-square w-7 items-center justify-center self-end rounded-full bg-[#0085FF]"
            >
              <AntDesign name="close" size={16} color="white" />
            </TouchableOpacity>

            <Text className="font-open-sans-600 text-[#505050]">PONTOS *</Text>

            <Controller
              control={control}
              name="points"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View className="mt-2">
                  <InputText
                    variant="secondary"
                    keyboardType="numeric"
                    value={value?.toString() || ""}
                    onChangeText={text =>
                      onChange(currencyMask(text.toString()))
                    }
                  />

                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />

            <Text className="mt-2 font-open-sans-600 text-[#505050]">
              Companhia aérea *
            </Text>

            <Controller
              control={control}
              name="airCompany"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View className="mt-2">
                  <Select
                    options={airCompanyOptions}
                    selectedOptionValue={value}
                    onSelect={onChange as (text: string) => void}
                  />

                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />

            <View className="mt-3">
              <Button
                text="Consumir pontos"
                isLoading={isSubmitting}
                onPress={handleConsumePoints}
              />
            </View>
          </View>
        </Pressable>
      </Modal>
    </Fragment>
  );
};
