import classNames from "classnames";
import { forwardRef, useState } from "react";
import { TextInput, TextInputProps } from "react-native";

interface InputCodeProps extends TextInputProps {}

export const InputCode = forwardRef<TextInput, InputCodeProps>((props, ref) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <TextInput
      {...props}
      ref={ref}
      className={classNames(
        "h-[65px] w-[70px] rounded border bg-white text-center text-3xl",
        isFocused ? "border-2 border-[#0085FF]" : "border-[#9BADCA]",
      )}
      keyboardType="numeric"
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    />
  );
});
