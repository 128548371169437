import { ApolloProvider } from "@apollo/client";
import {
  Inter_400Regular,
  Inter_500Medium,
  Inter_700Bold,
} from "@expo-google-fonts/inter";
import {
  OpenSans_300Light,
  OpenSans_400Regular,
  OpenSans_600SemiBold,
  OpenSans_700Bold,
} from "@expo-google-fonts/open-sans";
import {
  RobotoMono_300Light,
  RobotoMono_400Regular,
  RobotoMono_500Medium,
} from "@expo-google-fonts/roboto-mono";
import { NavigationContainer } from "@react-navigation/native";
import dayjs from "dayjs";
import ptBr from "dayjs/locale/pt-br";
import { useFonts } from "expo-font";
import * as ScreenOrientation from "expo-screen-orientation";
import { NativeWindStyleSheet } from "nativewind";
import { Platform, StatusBar, View } from "react-native";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Routes } from "~/routes";
import { navigationRef } from "~/routes/navigation-ref";
import { AuthProvider } from "./src/contexts/AuthContext";
import { apolloClient } from "./src/lib/apollo";

dayjs.locale(ptBr);

if (Platform.OS === "android" || Platform.OS === "ios") {
  ScreenOrientation.lockAsync(
    ScreenOrientation.OrientationLock.PORTRAIT_UP,
  ).catch(console.error);
} else if (Platform.OS === "web") {
  NativeWindStyleSheet.setOutput({ default: "native" });
}

export default function App() {
  const [hasLoadedFonts] = useFonts({
    Inter_400Regular,
    Inter_500Medium,
    Inter_700Bold,
    OpenSans_300Light,
    OpenSans_400Regular,
    OpenSans_600SemiBold,
    OpenSans_700Bold,
    RobotoMono_300Light,
    RobotoMono_400Regular,
    RobotoMono_500Medium,
  });

  if (!hasLoadedFonts) {
    return null;
  }

  return (
    <View className="flex-1">
      {Platform.OS === "web" && (
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      )}

      <StatusBar
        translucent
        barStyle="light-content"
        backgroundColor="transparent"
      />

      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <NavigationContainer ref={navigationRef}>
            <Routes />
          </NavigationContainer>
        </AuthProvider>
      </ApolloProvider>
    </View>
  );
}

if (Platform.OS === "web" && !__DEV__) {
  const serviceWorkerRegistration = require("./src/serviceWorkerRegistration");
  serviceWorkerRegistration.register();
}
