import { Modal, Pressable, Text, TouchableOpacity, View } from "react-native";

interface WarningCardProps {
  text: string;
  isOpen: boolean;
  description: string;
  onCancel?: () => void;
  onClose?: () => void;
  onConfirm?: () => void;
  cancelButtonText?: string;
  confirmButtonText?: string;
}

export const WarningCard: React.FC<WarningCardProps> = ({
  text,
  isOpen,
  onClose,
  onConfirm,
  description,
}) => {
  return (
    <Modal
      statusBarTranslucent
      transparent
      visible={isOpen}
      onRequestClose={onClose}
    >
      <Pressable
        onPress={event => {
          if (event.target === event.currentTarget) {
            onClose?.();
          }
        }}
        className="flex-1 items-center justify-center bg-black/30 p-4"
      >
        <View className="items-center justify-center rounded-lg bg-white p-8">
          <Text className="mt-4 text-center font-open-sans-600 text-base font-semibold text-[#999999]">
            {text}
          </Text>
          <Text className="mt-4 justify-center text-center font-open-sans-600 text-sm font-normal text-[#999999]">
            {description}
          </Text>
          <TouchableOpacity
            onPress={onConfirm}
            className="mt-6 w-full items-center justify-center rounded-full bg-[#EBEBEB] p-4"
          >
            <Text className="font-inter-500 font-medium text-[#999999]">
              RECEBI O CÓDIGO
            </Text>
          </TouchableOpacity>
        </View>
      </Pressable>
    </Modal>
  );
};
