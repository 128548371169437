import { gql } from "@apollo/client";

export interface MyBillingsQueryResponse {
  myBillings: {
    totalCount: number;
    billings: Array<{
      id: string;
      name: string;
      price: number;
      dueDate: string;
      description: string;
      paidAt?: string | null;
      chargedAt?: string | null;
      recurrenceMonths?: number | null;
      recurrenceNumber?: number | null;
    }>;
  };
}

export interface MyBillingsQueryVariables {
  filters?: {
    dueDate?: {
      eq?: string;
      gt?: string;
      gte?: string;
      lt?: string;
      lte?: string;
    };
    isCharged?: boolean;
  };
  pagination?: {
    page: number;
    pageSize: number;
  };
}

export const myBillingsQuery = gql`
  query MyBillings(
    $filters: MyBillingsFiltersInput
    $pagination: PaginationInput
  ) {
    myBillings(filters: $filters, pagination: $pagination) {
      totalCount
      billings {
        id
        name
        price
        paidAt
        dueDate
        chargedAt
        description
        recurrenceMonths
        recurrenceNumber
      }
    }
  }
`;
