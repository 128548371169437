import { Entypo } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useEffect } from "react";
import { ImageBackground, Text, View } from "react-native";
import { Button } from "~/components/Button";

export const RegisterBillingSuccess: React.FC = () => {
  const { navigate } = useNavigation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("MyBillings");
    }, 10000); //dez segundos

    return () => {
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <ImageBackground
      className="flex-1 bg-[#9FF4FF]"
      source={require("assets/gradient-eleva-2.png")}
      resizeMode="cover"
    >
      <View className=" flex-1 justify-center gap-8 p-6">
        <View className="items-center justify-center space-y-2">
          <View className="aspect-square w-[150px] items-center justify-center rounded-full border-2 border-white bg-[#0085FF]">
            {/* <LottieView
              source={require("assets/payment.json")}
              autoPlay
            /> */}
            <Entypo name="check" size={50} color="white" />
          </View>

          <Text className="text-center font-open-sans-600 text-3xl font-semibold text-[#0085FF]">
            Cadastro de fatura realizado
          </Text>

          <Text className="text-center font-open-sans-400 text-base font-semibold  text-[#080B2A]">
            Você está sendo redirecionado...
          </Text>
        </View>

        <View>
          <Button text="Continuar" onPress={() => navigate("MyBillings")} />
        </View>
      </View>
    </ImageBackground>
  );
};
