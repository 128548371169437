import { createStackNavigator } from "@react-navigation/stack";
import { Home } from "~/pages/Home";
import { Login } from "~/pages/Login";
import { Register } from "~/pages/Register";
import { RegisterStepTwo } from "~/pages/RegisterStepTwo";
import { RegisterValidation } from "~/pages/RegisterValidation";

const Stack = createStackNavigator();

export function AuthStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen
        name="Login"
        component={Login}
        options={{ title: "Login" }}
      />

      <Stack.Screen name="Home" component={Home} options={{ title: "Home" }} />

      <Stack.Screen
        name="Register"
        component={Register}
        options={{ title: "Cadastro | Dados Pessoais" }}
      />

      <Stack.Screen
        name="RegisterStepTwo"
        component={RegisterStepTwo}
        options={{ title: "Cadastro | Endereço" }}
      />

      <Stack.Screen
        name="RegisterValidation"
        component={RegisterValidation}
        options={{ title: "Cadastro | Confirmar número" }}
      />
    </Stack.Navigator>
  );
}
