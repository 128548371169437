import { ApolloError } from "@apollo/client";
import { Ionicons } from "@expo/vector-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Text, TouchableOpacity, View } from "react-native";
import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { Layout } from "~/components/Layout";
import { InputText } from "~/components/TextInput";
import { WarningCard } from "~/components/WarningCard";
import {
  RegisterMutationResponse,
  RegisterMutationVariables,
  registerMutation,
} from "~/graphql/mutations/register";
import { apolloClient } from "~/lib/apollo";
import { alert } from "~/utils/alert";
import { cepMask } from "~/utils/masks/cepMask";
import { RegisterFormInput } from "~/validation/register";
import {
  RegisterStepTwoFormInput,
  registerStepTwoValidationSchema,
} from "~/validation/register-step-two";

export const RegisterStepTwo: React.FC = () => {
  const route = useRoute();
  const userData = route.params as RegisterFormInput;
  const { goBack, navigate } = useNavigation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<RegisterStepTwoFormInput>({
    resolver: zodResolver(registerStepTwoValidationSchema),
  });

  function handleOpenDeleteModal() {
    setIsDeleteModalOpen(true);
  }

  function handleCloseDeleteModal() {
    setIsDeleteModalOpen(false);
  }

  function handleOnConfirmModal() {
    navigate("RegisterValidation", {
      email: userData.email,
    });
  }

  const handleRegister = handleSubmit(async values => {
    try {
      await apolloClient.mutate<
        RegisterMutationResponse,
        RegisterMutationVariables
      >({
        mutation: registerMutation,
        variables: {
          input: {
            cpf: userData.cpf,
            name: userData.name,
            email: userData.email,
            password: userData.password,
            birthDate: userData.birthDate,
            phoneNumber: userData.phoneNumber,

            address: {
              city: values.city,
              district: values.district,
              street: values.address,
              number: values.addressNumber,
              zipcode: values.zipCode,
              state: values.UF,
            },
          },
        },
      });
      handleOpenDeleteModal();
    } catch (error) {
      let errorMessage = "Não foi possivel fazer o cadastro, tente novamente!";
      if (error instanceof ApolloError) {
        errorMessage = error.message;
      }
      alert("error", errorMessage);
      console.log(JSON.stringify(error, null, 2));
    }
  });

  return (
    <Layout>
      <View className="p-4">
        <WarningCard
          text="Sua inscrição foi enviada"
          description="e em breve você receberá um SMS com um código de 5 números para completar seu registro!"
          isOpen={isDeleteModalOpen}
          onCancel={handleCloseDeleteModal}
          onClose={handleCloseDeleteModal}
          onConfirm={handleOnConfirmModal}
        />
        <TouchableOpacity className="ml-4 mt-14" onPress={goBack}>
          <Ionicons name="chevron-back" size={30} color="white" />
        </TouchableOpacity>
        <View className="items-center justify-center">
          <Text className="font-open-sans-400 text-xl font-normal text-white">
            INSCRIÇÃO
          </Text>
        </View>
        <View className="mt-2 p-4">
          <Text className="mb-4 font-open-sans-600 text-xs font-semibold text-[#505050]">
            ENDEREÇO
          </Text>

          <View className="mt-2">
            <Controller
              control={control}
              name="zipCode"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View>
                  <InputText
                    placeholder="CEP"
                    value={value || ""}
                    variant="secondary"
                    onChangeText={text => onChange(cepMask(text))}
                  />
                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />
          </View>
          <View className="mt-4 w-full flex-row items-center space-x-2">
            <Controller
              control={control}
              name="address"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View className="flex-1">
                  <InputText
                    value={value || ""}
                    variant="secondary"
                    placeholder="Logradouro"
                    onChangeText={onChange}
                  />
                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />

            <Controller
              control={control}
              name="addressNumber"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View className="ml-2">
                  <InputText
                    placeholder="Num"
                    value={value || ""}
                    variant="secondary"
                    className="w-[64px]"
                    onChangeText={onChange}
                  />
                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />
          </View>
          <Controller
            control={control}
            name="complement"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View>
                <InputText
                  className="mt-4"
                  value={value || ""}
                  variant="secondary"
                  onChangeText={onChange}
                  placeholder="Complemento"
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />
          <Controller
            control={control}
            name="district"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View>
                <InputText
                  className="mt-4"
                  value={value || ""}
                  variant="secondary"
                  onChangeText={onChange}
                  placeholder="Bairro"
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />
          <View className="mt-4 w-full flex-row items-center space-x-2">
            <Controller
              control={control}
              name="city"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View className="flex-1">
                  <InputText
                    value={value || ""}
                    variant="secondary"
                    placeholder="Cidade"
                    onChangeText={onChange}
                  />
                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />

            <Controller
              control={control}
              name="UF"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View className="ml-2">
                  <InputText
                    placeholder="UF"
                    value={value || ""}
                    variant="secondary"
                    className="w-[64px]"
                    onChangeText={onChange as (text: string) => void}
                  />
                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />
          </View>
        </View>

        <Button
          text="Inscrever-me"
          className="mt-16"
          isLoading={isSubmitting}
          onPress={handleRegister}
        />
      </View>
    </Layout>
  );
};
