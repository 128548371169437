import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  MyBillingsQueryResponse,
  MyBillingsQueryVariables,
  myBillingsQuery,
} from "~/graphql/queries/my-billings";

export function useMyBillingsQuery(
  options?: QueryHookOptions<MyBillingsQueryResponse, MyBillingsQueryVariables>,
) {
  return useQuery<MyBillingsQueryResponse, MyBillingsQueryVariables>(
    myBillingsQuery,
    options,
  );
}
