export function cepMask(value: string): string {
  value = value.replace(/\D/g, ""); // remove não númericos
  value = value.substring(0, 8); // limita caracteres

  if (value.length <= 5) {
    return value;
  }

  return `${value.substring(0, 5)}-${value.substring(5)}`;
}
