import { gql } from "@apollo/client";

export interface RefreshAccessTokenResponse {
  refreshAccessToken: {
    accessToken: string;
    refreshToken: string;
  };
}

export interface RefreshAccessTokenVariables {
  refreshToken: string;
}

export const refreshAccessTokenMutation = gql`
  mutation RefreshAccessToken($refreshToken: String!) {
    refreshAccessToken(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;
