import { FontAwesome5, Ionicons } from "@expo/vector-icons";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import classNames from "classnames";
import dayjs from "dayjs";
import { Fragment, useCallback, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  ImageBackground,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { QueryFailed } from "~/components/QueryFailed";
import { SubsidyCard } from "~/components/SubsidyCard";
import { useSubsidiesQuery } from "~/hooks/useSubsidies";
import { ucfirst } from "~/utils/ucfirst";

export const Subsidies: React.FC = () => {
  const { navigate } = useNavigation();
  const [date, setDate] = useState(() => dayjs());
  const { data, error, loading, refetch } = useSubsidiesQuery({
    variables: {
      filters: {
        month: date.get("month") + 1,
        year: date.get("year"),
      },
    },
  });

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  return (
    <ImageBackground
      className="flex-1 bg-[#9FF4FF]"
      source={require("assets/gradient-eleva-2.png")}
      resizeMode="cover"
    >
      <View className="flex-1 pt-16">
        <View className="items-center">
          <View className="mb-2 w-[250px] flex-row items-center justify-between space-x-8">
            <TouchableOpacity
              onPress={() => {
                setDate(currentDate => currentDate.subtract(1, "month"));
              }}
            >
              <FontAwesome5 name="angle-left" size={24} color="#00000060" />
            </TouchableOpacity>

            <Text className="font-open-sans-400 text-sm text-black opacity-60">
              {ucfirst(date.format("MMMM [de] YYYY"))}
            </Text>

            <TouchableOpacity
              onPress={() => {
                setDate(currentDate => currentDate.add(1, "month"));
              }}
            >
              <FontAwesome5 name="angle-right" size={24} color="#00000060" />
            </TouchableOpacity>
          </View>
        </View>

        <View className="mt-6 flex-1 bg-[#F5F5F5] pt-3">
          {loading ? (
            <View className="mt-10 items-center justify-center">
              <ActivityIndicator size={40} />
            </View>
          ) : !data || error ? (
            <QueryFailed refetch={refetch} />
          ) : (
            <Fragment>
              <View className="flex-row items-center justify-center ">
                <Text className="font-open-sans-500 text-sm text-[#999999]">
                  Neste mês há
                </Text>

                <Text className="font-open-sans-700 text-sm text-[#999999]">
                  {" "}
                  {data.subsidies.length}{" "}
                </Text>

                <Text className="font-open-sans-500 text-sm text-[#999999]">
                  subsídio{data.subsidies.length !== 1 && "s"} cadastrado
                  {data.subsidies.length !== 1 && "s"}.
                </Text>
              </View>

              <FlatList
                data={data.subsidies}
                keyExtractor={subsidy => subsidy.id}
                ItemSeparatorComponent={() => <View className="my-3" />}
                renderItem={({ item }) => <SubsidyCard subsidy={item} />}
                contentContainerStyle={{
                  paddingTop: 16,
                  paddingBottom: 40,
                  paddingHorizontal: 24,
                }}
                ListEmptyComponent={() => (
                  <View>
                    <Text className="mt-10 p-4 text-center font-inter-400 text-sm font-semibold text-black opacity-80">
                      Parece que ainda não foi cadastrado nenhum subsídio para
                      este mês 😕
                    </Text>
                  </View>
                )}
              />
            </Fragment>
          )}
        </View>

        <TouchableOpacity
          style={{ elevation: 8 }}
          onPress={() => navigate("CreateSubsidy")}
          className={classNames(
            "absolute bottom-2 right-2 z-40 flex aspect-square h-14 w-14 items-center justify-center rounded-full bg-[#00B2FF] px-1.5",
            Platform.OS === "web" && "shadow-lg",
          )}
        >
          <Ionicons name="add-outline" size={24} color="white" />
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};
