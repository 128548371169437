import { useFocusEffect } from "@react-navigation/native";
import dayjs from "dayjs";
import { useCallback } from "react";
import { Text, View } from "react-native";
import { useMyBillingsQuery } from "~/hooks/useMyBillings";

interface MyBillingsHeadingProps {
  filterDate: dayjs.Dayjs;
}

export const MyBillingsHeading: React.FC<MyBillingsHeadingProps> = ({
  filterDate,
}) => {
  const { data, refetch } = useMyBillingsQuery({
    variables: {
      filters: {
        isCharged: true,
        dueDate: {
          lte: dayjs(filterDate).endOf("month").format("YYYY-MM-DD"),
          gte: dayjs(filterDate).startOf("month").format("YYYY-MM-DD"),
        },
      },
    },
  });

  useFocusEffect(
    useCallback(() => {
      refetch();
    }, [refetch]),
  );

  return (
    <>
      <Text className="mb-2 font-open-sans-400 text-sm font-normal uppercase text-black opacity-60">
        Suas contas
      </Text>

      <View className="flex-row">
        <Text className="font-open-sans-400 text-xs font-normal text-black opacity-60">
          {data?.myBillings.totalCount === 1 ? "Foi paga" : "Foram pagas"}{" "}
        </Text>
        <Text className="font-open-sans-700 text-xs font-bold text-black opacity-80">
          {data?.myBillings.totalCount ?? 0}
        </Text>
        <Text className="font-open-sans-400 text-xs font-normal text-black opacity-60">
          {" "}
          conta{data?.myBillings.totalCount !== 1 && "s"} neste mês
        </Text>
      </View>
    </>
  );
};
