import { z } from "zod";

export const verifyCodeValidationSchema = z.object({
  code: z
    .string({ required_error: "Este campo é obrigatório." })
    .min(5, "Código inválido.")
    .trim(),
  email: z
    .string({ required_error: "Este campo é obrigatório." })
    .email("Digite um e-mail válido.")
    .trim(),
});

export type VerifyCodeFormInput = z.input<typeof verifyCodeValidationSchema>;
