import { gql } from "@apollo/client";
import type { AddressState } from "~/types/AddressState";

export interface RegisterMutationResponse {
  register: {
    id: string;
  };
}

export interface RegisterMutationVariables {
  input: {
    email: string;
    password: string;
    phoneNumber: string;
    birthDate: string;
    cpf: string;
    name: string;
    address: {
      zipcode: string;
      state: AddressState;
      city: string;
      district: string;
      street: string;
      complement?: string | null;
      number?: string | null;
    };
  };
}

export const registerMutation = gql`
  mutation Register($input: RegisterInput!) {
    register(input: $input) {
      id
    }
  }
`;
