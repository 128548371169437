import { gql } from "@apollo/client";
import type { BillingTransferAccountType } from "~/types/billing-transfer-account";
import type { PixKeyType } from "~/types/pix-key-type";

export interface CreateBillingMutationResponse {
  id: string;
}

export interface CreateBillingMutationVariables {
  input: {
    name: string;
    price: number;
    cardId: string;
    dueDate: string;
    description: string;
    recurrenceMonths: number | undefined;
    transfer: {
      favoredName: string;
      cpfCnpj: string;
      pix?: {
        key: string;
        type: PixKeyType;
      };
      ted?: {
        compe: string;
        branch: string;
        account: string;
        accountType: BillingTransferAccountType;
      };
    };
  };
}

export const createBillingMutation = gql`
  mutation CreateBilling($input: CreateBillingInput!) {
    createBilling(input: $input) {
      id
    }
  }
`;
