import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  loginMutation,
  LoginMutationResponse,
  LoginMutationVariables,
} from "~/graphql/mutations/login";
import {
  refreshAccessTokenMutation,
  RefreshAccessTokenResponse,
  RefreshAccessTokenVariables,
} from "~/graphql/mutations/refresh-access-token";
import { meQuery, MeQueryResponse } from "~/graphql/queries/me";
import { apolloClient } from "~/lib/apollo";
import { User } from "~/types/User";
import { setAccessToken } from "~/utils/accessToken";
import { storageRefreshTokenKey } from "~/utils/constants";

export interface LoginCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  loading: boolean;
  user: User | undefined;
  isInitialLoad: boolean;
  isAuthenticated: boolean;
  fetchUser: () => Promise<void>;
  logout: () => void | Promise<void>;
  setUser: Dispatch<SetStateAction<User | undefined>>;
  login: (data: LoginCredentials) => Promise<void>;
}

export const AuthContext = createContext({} as AuthContextData);

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const fetchUser = useCallback(async () => {
    const { data } = await apolloClient.query<MeQueryResponse>({
      query: meQuery,
      fetchPolicy: "network-only",
    });

    setUser(data.me);
  }, []);

  useEffect(() => {
    AsyncStorage.getItem(storageRefreshTokenKey)
      .then(async refreshToken => {
        if (!refreshToken) return;

        const { data } = await apolloClient.mutate<
          RefreshAccessTokenResponse,
          RefreshAccessTokenVariables
        >({
          fetchPolicy: "network-only",
          mutation: refreshAccessTokenMutation,
          variables: {
            refreshToken,
          },
        });

        if (!data) {
          throw new Error("No refresh token data.");
        }

        setAccessToken(data.refreshAccessToken.accessToken);
        await Promise.all([
          fetchUser(),
          AsyncStorage.setItem(
            storageRefreshTokenKey,
            data.refreshAccessToken.refreshToken,
          ).catch(console.error),
        ]);
      })
      .catch(error => {
        console.error(error);
        setAccessToken(null);
        setUser(undefined);
        AsyncStorage.removeItem(storageRefreshTokenKey).catch(console.error);
      })
      .finally(() => setIsInitialLoad(false));
  }, [fetchUser]);

  const login: AuthContextData["login"] = useCallback(async input => {
    setIsLoading(true);

    const { data } = await apolloClient.mutate<
      LoginMutationResponse,
      LoginMutationVariables
    >({
      fetchPolicy: "no-cache",
      mutation: loginMutation,
      variables: { input },
    });

    if (!data) {
      setIsLoading(false);
      throw new Error("No login data.");
    }

    await AsyncStorage.setItem(
      storageRefreshTokenKey,
      data.login.refreshToken,
    ).catch(console.error);

    setAccessToken(data.login.accessToken);
    setUser(data.login.user);
    setIsLoading(false);
  }, []);

  const logout: AuthContextData["logout"] = useCallback(async () => {
    await Promise.all([
      apolloClient.clearStore(),
      AsyncStorage.removeItem(storageRefreshTokenKey),
    ]).catch(console.error);

    setAccessToken(null);
    setUser(undefined);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        setUser,
        fetchUser,
        isInitialLoad,
        loading: isLoading,
        isAuthenticated: Boolean(user),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
