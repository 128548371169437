export class DateConverter {
  static fromBrazilianToUniversal(date?: string | null) {
    if (!date) {
      return date;
    }

    const [DD, MM, YYYY] = date.split("/");
    return `${YYYY}-${MM}-${DD}`;
  }

  static fromUniversalToBrazilian(date?: string | null) {
    if (!date) {
      return date;
    }

    const [YYYY, MM, DD] = date.split("-");
    return `${DD}/${MM}/${YYYY}`;
  }
}
