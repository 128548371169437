import { ApolloError } from "@apollo/client";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigation } from "@react-navigation/native";
import { Controller, useForm } from "react-hook-form";
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import * as Animatable from "react-native-animatable";
import KeyIcon from "~/assets/icons/key-eleva.svg";
import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { PasswordInput } from "~/components/PasswordInput";
import { SvgIcon } from "~/components/SvgIcon";
import { InputText } from "~/components/TextInput";
import { useAuth } from "~/hooks/useAuth";
import { alert } from "~/utils/alert";
import { LoginFormInput, loginValidationSchema } from "~/validation/login";

export const Login: React.FC = () => {
  const { navigate } = useNavigation();
  const { login } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<LoginFormInput>({
    resolver: zodResolver(loginValidationSchema),
  });

  const handleLogin = handleSubmit(async values => {
    try {
      await login(values);
    } catch (error) {
      let errorMessage = "Não foi possivel fazer login, tente novamente!";

      if (error instanceof ApolloError) {
        errorMessage = error.message;
      }

      alert("error", errorMessage);
      console.log(error);
      console.log(JSON.stringify(error, null, 2));
    }
  });

  return (
    <ImageBackground
      className="h-[500px] flex-1 bg-white"
      source={require("../assets/eleva-bg-2.png")}
      resizeMode="cover"
    >
      <ScrollView>
        <View className="mt-4 items-center justify-center">
          <Image
            source={require("../assets/logo-eleva-2.jpg")}
            resizeMode="contain"
            className="z-40 mt-2 h-60 w-60"
          />
        </View>
        <View className="justify-center p-10">
          <View>
            <Animatable.View animation={"bounceInRight"} duration={1200}>
              <Controller
                control={control}
                name="email"
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <View>
                    <InputText
                      variant="primary"
                      value={value || ""}
                      autoCapitalize="none"
                      onChangeText={onChange}
                      placeholder="Seu email"
                      keyboardType="email-address"
                      placeholderTextColor="#BDBDBD"
                    />
                    <ErrorMessage message={error?.message} />
                  </View>
                )}
              />
            </Animatable.View>
          </View>
          <Animatable.View
            duration={1200}
            animation="bounceInRight"
            className="w-full flex-row items-start space-x-2"
          >
            <Controller
              control={control}
              name="password"
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <View className="flex-1">
                  <PasswordInput
                    className="mt-2"
                    variant="primary"
                    value={value || ""}
                    returnKeyType="send"
                    autoCapitalize="none"
                    placeholder="Sua senha"
                    onChangeText={onChange}
                    onSubmitEditing={handleLogin}
                  />
                  <ErrorMessage message={error?.message} />
                </View>
              )}
            />
            <TouchableOpacity
              className="mt-2 h-[40px] w-[64px] items-center justify-center rounded-sm bg-[#0085FF] py-3"
              disabled={isSubmitting}
              onPress={handleLogin}
            >
              {isSubmitting ? (
                <ActivityIndicator size={24} color="white" />
              ) : (
                <SvgIcon component={KeyIcon} />
              )}
            </TouchableOpacity>
          </Animatable.View>
          <View className="flex-row justify-between">
            <TouchableOpacity>
              <Text className="mt-2 text-center font-open-sans-400 text-sm text-[#505050]">
                Esqueci a senha
              </Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                navigate("RegisterValidation", {});
              }}
            >
              <Text className="mt-2 text-center font-open-sans-400 text-sm text-[#505050]">
                Verificar código
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        <View className="mt-8 flex-1 items-center justify-center bg-white p-8">
          <View className="border border-[#DADADA] p-4 font-open-sans-400 opacity-50">
            <Text className="text-[#505050]">
              Eu ainda não tenho uma conta Eleva, vou increver-me agora!
            </Text>
          </View>
          <Button
            text="Inscrever-me"
            className="mt-4"
            onPress={() => navigate("Register")}
          />
          <Text className="mt-4 font-open-sans-400 text-[#505050]">
            Quero saber mais.
          </Text>
        </View>
      </ScrollView>
    </ImageBackground>
  );
};
