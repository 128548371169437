import { gql } from "@apollo/client";

export interface CreateSubsidyMutationResponse {
  id: string;
}

export interface CreateSubsidyMutationVariables {
  input: {
    company: string;
    cpf: string;
    month: number;
    year: number;
    amount: number;
  };
}

export const createSubsidyMutation = gql`
  mutation CreateSubsidy($input: CreateSubsidyInput!) {
    createSubsidy(input: $input) {
      id
    }
  }
`;
