import { z } from "zod";
import { AddressState } from "~/types/AddressState";

export const registerStepTwoValidationSchema = z.object({
  city: z.string().trim(),
  zipCode: z.string().trim(),
  address: z.string().trim(),
  district: z.string().trim(),
  UF: z.nativeEnum(AddressState),
  complement: z.string().trim().nullish(),
  addressNumber: z.string().trim().nullish(),
});

export type RegisterStepTwoFormInput = z.input<
  typeof registerStepTwoValidationSchema
>;
