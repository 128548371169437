import { Platform } from "react-native";
import type { SvgProps } from "react-native-svg";

interface SvgIconProps extends SvgProps {
  component: React.FC<SvgProps>;
}

export const SvgIcon: React.FC<SvgIconProps> = ({
  component: Component,
  ...props
}) => {
  if (Platform.OS === "web") {
    return <img src={Component as unknown as string} />;
  }

  return <Component {...props} />;
};
