import { gql } from "@apollo/client";

export interface ResendVerificationCodeResponse {
  resendVerificationCode: boolean;
}

export interface ResendVerificationCodeVariables {
  email: string;
}

export const resendVerificationCodeMutation = gql`
  mutation ResendVerificationCode($email: String!) {
    resendVerificationCode(email: $email)
  }
`;
