import { z } from "zod";
import { isValidCpf } from "~/utils/isValidCpf";

export const createSubsidyValidationSchema = z.object({
  company: z.string({ required_error: "Este campo é obrigatório." }).trim(),
  cpf: z
    .string({ required_error: "Este campo é obrigatório." })
    .trim()
    .refine(cpf => isValidCpf(cpf), "Este CPF é inválido."),
  month: z
    .string({ required_error: "Este campo é obrigatório." })
    .trim()
    .refine(month => {
      const monthNumber = Number(month);

      if (isNaN(monthNumber)) {
        return false;
      }

      if (month.includes(".") || month.includes(",")) {
        return false;
      }

      return monthNumber >= 1 && monthNumber <= 12;
    }, "Este mês é inválido."),
  year: z
    .string({ required_error: "Este campo é obrigatório." })
    .trim()
    .refine(year => /^\d{4}$/.test(year), "Este ano é inválido.")
    .refine(
      year => Number(year) >= new Date().getFullYear(),
      "O ano deve ser no mínimo o ano atual.",
    ),
  amount: z
    .string({ required_error: "Este campo é obrigatório." })
    .transform(price => price.replace(/[^0-9,]/g, "").replace(",", "."))
    .refine(price => !isNaN(Number(price)), "Digite um valor válido."),
});

export type CreateSubsidyFormInput = z.input<
  typeof createSubsidyValidationSchema
>;
