export const API_BASE_URL = "http://192.168.1.32:3333";
// export const API_BASE_URL = "http://192.168.0.99:3333";
// export const API_BASE_URL = "https://api-eleva-uat.qodeless.com.br";

export const storageRefreshTokenKey = "@eleva/refreshToken";

export const dateLabelMap: Record<string, string> = {
  "01": "JAN",
  "02": "FEV",
  "03": "MAR",
  "04": "ABR",
  "05": "MAI",
  "06": "JUN",
  "07": "JUL",
  "08": "AGO",
  "09": "SET",
  "10": "OUT",
  "11": "NOV",
  "12": "DEZ",
};
