import { Ionicons } from "@expo/vector-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigation } from "@react-navigation/native";
import { Controller, useForm } from "react-hook-form";
import {
  ActivityIndicator,
  ImageBackground,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { InputText } from "~/components/TextInput";
import {
  CreateCardMutationResponse,
  CreateCardMutationVariables,
  createCardMutation,
} from "~/graphql/mutations/create-card";
import { apolloClient } from "~/lib/apollo";
import { alert } from "~/utils/alert";
import { dateCardMask } from "~/utils/masks/dateCardMask";
import { creditCardNumberMask } from "~/utils/masks/numberCard";
import { securityCodeMask } from "~/utils/masks/securityCode";
import {
  RegisterCreditCardFormInput,
  registerCardValidationSchema,
} from "~/validation/register-card";

export const RegisterCard: React.FC = () => {
  const { goBack, navigate } = useNavigation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<RegisterCreditCardFormInput>({
    resolver: zodResolver(registerCardValidationSchema),
  });

  const handleRegisterCard = handleSubmit(async values => {
    try {
      const response = await apolloClient.mutate<
        CreateCardMutationResponse,
        CreateCardMutationVariables
      >({
        mutation: createCardMutation,
        variables: {
          input: {
            expirationDate: values.expirationDate,
            holderName: values.holderName,
            number: values.number,
            securityCode: values.securityCode,
          },
        },
      });
      if (response.errors) {
        return alert("error", response.errors[0].message);
      }
      navigate("RegisterSuccess");
    } catch (error) {
      alert(
        "error",
        "Não foi possível concluir o cadastro. Tente novamente mais tarde.",
      );
      console.log(JSON.stringify(error, null, 2));
    }
  });

  return (
    <ImageBackground
      className="flex-1 bg-[#9FF4FF]"
      source={require("assets/gradient-eleva-2.png")}
      resizeMode="cover"
    >
      <TouchableOpacity className="ml-4 mt-24" onPress={goBack}>
        <Ionicons name="chevron-back" size={30} color="#0789E5" />
      </TouchableOpacity>
      <View className="items-center justify-center">
        <Text className="text-xl font-normal text-black opacity-60">
          CADASTRO DE CARTÃO
        </Text>
        <Text className="mt-4 text-sm font-light text-black opacity-60">
          Cadastre um novo cartão e já comece a usar!{" "}
        </Text>
      </View>
      <View className="mt-2 p-4">
        <Controller
          control={control}
          name="holderName"
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <View>
              <InputText
                className="mt-4"
                value={value || ""}
                variant="secondary"
                onChangeText={onChange}
                placeholder="Nome do titular"
              />
              <ErrorMessage message={error?.message} />
            </View>
          )}
        />

        <View className="mt-4 w-full flex-row items-center space-x-2">
          <Controller
            control={control}
            name="number"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <View className="flex-1 space-x-2">
                <InputText
                  maxLength={19}
                  className="mr-0.5"
                  variant="secondary"
                  value={value || ""}
                  onChangeText={text => {
                    onChange(creditCardNumberMask(text));
                  }}
                  placeholder="Número do cartão"
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />
        </View>
        <View className="mt-4 flex-row">
          <Controller
            control={control}
            name="securityCode"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <View className="mr-2 flex-1">
                <InputText
                  maxLength={4}
                  placeholder="CVV"
                  value={value || ""}
                  variant="secondary"
                  onChangeText={text => {
                    onChange(securityCodeMask(text));
                  }}
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />
          <Controller
            control={control}
            name="expirationDate"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <View className="flex-1">
                <InputText
                  maxLength={5}
                  value={value || ""}
                  variant="secondary"
                  placeholder="Data de expiração"
                  onChangeText={text => {
                    onChange(dateCardMask(text));
                  }}
                />
                <ErrorMessage message={error?.message} />
              </View>
            )}
          />
        </View>

        <View>
          {isSubmitting ? (
            <View className="mt-16 items-center justify-center p-4">
              <ActivityIndicator size={28} />
            </View>
          ) : (
            <Button
              text="Cadastrar cartão"
              className="mt-16"
              disabled={isSubmitting}
              onPress={handleRegisterCard}
            />
          )}
        </View>
      </View>
    </ImageBackground>
  );
};
