import { z } from "zod";

export const passwordValidationSchema = z
  .string({ required_error: "A senha é obrigatória" })
  .min(8, "A senha deve conter no mínimo 8 caracteres")
  .regex(/[0-9]/, "A senha deve conter no mínimo 1 número")
  .regex(
    /[a-záàâãéèêíïóôõöúçñ]/,
    "A senha deve conter no mínimo 1 letra minúscula",
  )
  .regex(
    /[A-ZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/,
    "A senha deve conter no mínimo 1 letra maiúscula",
  )
  .regex(
    /[^0-9a-záàâãéèêíïóôõöúçñA-ZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ]/,
    "A senha deve conter no mínimo 1 símbolo",
  );
