import { z } from "zod";
import { AirCompany } from "~/types/AirCompany";

export const consumePointsValidationSchema = z.object({
  points: z
    .string({ required_error: "Este campo é obrigatório." })
    .transform(price => price.replace(/[^0-9,]/g, "").replace(",", "."))
    .refine(price => !isNaN(Number(price)), "Digite uma quantidade válida."),
  airCompany: z.nativeEnum(AirCompany, {
    required_error: "Este campo é obrigatório",
  }),
});

export type ConsumePointsFormInput = z.input<
  typeof consumePointsValidationSchema
>;
