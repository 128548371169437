import { createStackNavigator } from "@react-navigation/stack";
import { Cards } from "~/pages/Cards";
import { RegisterCard } from "~/pages/RegisterCard";
import { RegisterSuccess } from "~/pages/RegisterSuccess";

const { Navigator, Screen } = createStackNavigator();

interface CardStackRoutesProps {}

export const CardsStackRoutes: React.FC<CardStackRoutesProps> = () => (
  <Navigator screenOptions={{ headerShown: false }}>
    <Screen name="Cards" component={Cards} options={{ title: "Cartões" }} />

    <Screen
      name="RegisterCard"
      component={RegisterCard}
      options={{ title: "Cadastrar cartão" }}
    />

    <Screen
      name="RegisterSuccess"
      component={RegisterSuccess}
      options={{ title: "Cadastro de cartão com sucesso" }}
    />
  </Navigator>
);
