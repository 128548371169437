import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigation } from "@react-navigation/native";
import dayjs from "dayjs";
import { Controller, useForm } from "react-hook-form";
import {
  ImageBackground,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Button } from "~/components/Button";
import { ErrorMessage } from "~/components/ErrorMessage";
import { Select, SelectOption } from "~/components/Select";
import { InputText } from "~/components/TextInput";
import {
  CreateSubsidyMutationResponse,
  CreateSubsidyMutationVariables,
  createSubsidyMutation,
} from "~/graphql/mutations/create-subsidy";
import { apolloClient } from "~/lib/apollo";
import { alert } from "~/utils/alert";
import { cpfMask } from "~/utils/masks/cpfMask";
import { currencyMask } from "~/utils/masks/currencyMask";
import {
  CreateSubsidyFormInput,
  createSubsidyValidationSchema,
} from "~/validation/create-subsidy";

const monthOptions: SelectOption[] = [
  {
    value: "1",
    label: "Janeiro",
  },
  {
    value: "2",
    label: "Fevereiro",
  },
  {
    value: "3",
    label: "Março",
  },
  {
    value: "4",
    label: "Abril",
  },
  {
    value: "5",
    label: "Maio",
  },
  {
    value: "6",
    label: "Junho",
  },
  {
    value: "7",
    label: "Julho",
  },
  {
    value: "8",
    label: "Agosto",
  },
  {
    value: "9",
    label: "Setembro",
  },
  {
    value: "10",
    label: "Outubro",
  },
  {
    value: "11",
    label: "Novembro",
  },
  {
    value: "12",
    label: "Dezembro",
  },
];

export const CreateSubsidy: React.FC = () => {
  const today = dayjs();

  const { goBack, navigate } = useNavigation();
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<CreateSubsidyFormInput>({
    resolver: zodResolver(createSubsidyValidationSchema),
    defaultValues: {
      company: "",
      cpf: "",
      month: String(today.get("month") + 1),
      year: today.get("year").toString(),
      amount: "",
    },
  });

  const handleCreateSubsidy = handleSubmit(async values => {
    try {
      const response = await apolloClient.mutate<
        CreateSubsidyMutationResponse,
        CreateSubsidyMutationVariables
      >({
        mutation: createSubsidyMutation,
        variables: {
          input: {
            company: values.company,
            cpf: values.cpf,
            month: parseInt(values.month, 10),
            year: parseInt(values.year, 10),
            amount: Number(values.amount),
          },
        },
      });

      if (response.errors) {
        return alert("error", response.errors[0].message);
      }

      navigate("RegisterSubsidySuccess");
    } catch (error) {
      alert(
        "error",
        "Não foi possível concluir o cadastro. Tente novamente mais tarde.",
      );

      console.log(JSON.stringify(error, null, 2));
    }
  });

  return (
    <ImageBackground
      className="h-[375px] flex-1 bg-white"
      source={require("assets/gradient-eleva-2.png")}
      resizeMode="cover"
    >
      <TouchableOpacity className="ml-4 mt-16" onPress={goBack}>
        <Ionicons name="chevron-back" size={30} color="#0789E5" />
      </TouchableOpacity>

      <ScrollView contentContainerStyle={{ padding: 24 }}>
        <View className="flex-row items-center space-x-2">
          <MaterialCommunityIcons
            size={50}
            color="#00000060"
            name="account-cash"
          />

          <Text className="font-open-sans-600 text-sm text-black opacity-60">
            CADASTRAR SUBSÍDIO
          </Text>
        </View>

        <View className="mt-4 flex-1 space-y-2">
          <Text className="font-open-sans-600 text-[#505050]">EMPRESA *</Text>

          <Controller
            control={control}
            name="company"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View className="mt-2">
                <InputText
                  value={value}
                  variant="secondary"
                  onChangeText={onChange}
                  placeholder="Ex.: Qodeless"
                />

                <ErrorMessage message={error?.message} />
              </View>
            )}
          />

          <Text className="font-open-sans-600 text-[#505050]">CPF *</Text>

          <Controller
            control={control}
            name="cpf"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View className="mt-2">
                <InputText
                  value={value}
                  maxLength={14}
                  variant="secondary"
                  placeholder="Ex.: 000.000.000-00"
                  onChangeText={text => onChange(cpfMask(text))}
                />

                <ErrorMessage message={error?.message} />
              </View>
            )}
          />

          <Text className="font-open-sans-600 text-[#505050]">MÊS *</Text>

          <Controller
            control={control}
            name="month"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View className="mt-2">
                <Select
                  onSelect={onChange}
                  options={monthOptions}
                  selectedOptionValue={value}
                  placeholder="ESCOLHA UM MÊS"
                />

                <ErrorMessage message={error?.message} />
              </View>
            )}
          />

          <Text className="font-open-sans-600 text-[#505050]">ANO *</Text>

          <Controller
            control={control}
            name="year"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View className="mt-2">
                <InputText
                  maxLength={4}
                  value={value}
                  variant="secondary"
                  onChangeText={onChange}
                  placeholder="Ex.: 2023"
                />

                <ErrorMessage message={error?.message} />
              </View>
            )}
          />

          <Text className="font-open-sans-600 text-[#505050]">VALOR *</Text>

          <Controller
            control={control}
            name="amount"
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <View className="mt-2">
                <InputText
                  prefix="R$"
                  variant="secondary"
                  keyboardType="numeric"
                  value={value.toString()}
                  onChangeText={text => onChange(currencyMask(text.toString()))}
                />

                <ErrorMessage message={error?.message} />
              </View>
            )}
          />

          <View>
            <Button
              text="Adicionar"
              isLoading={isSubmitting}
              onPress={handleCreateSubsidy}
            />
          </View>
        </View>
      </ScrollView>
    </ImageBackground>
  );
};
