import { QueryHookOptions, useQuery } from "@apollo/client";
import {
  MyPointsHistoryResponse,
  myPointsHistoryQuery,
} from "~/graphql/queries/my-points-history";

export function useMyPointsHistory(
  options?: QueryHookOptions<MyPointsHistoryResponse>,
) {
  return useQuery<MyPointsHistoryResponse>(myPointsHistoryQuery, options);
}
